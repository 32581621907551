import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { MainContext } from "../../App";

import { GetServices, PutService } from "../../api/Services";
import { goTo } from "../global/GlobalTools";

const customStylesService = {
  rowContainerService: {
    background: "#ffffff",
    border: "1px solid #ececee",
    borderRadius: "8px",
    height: "60px",
    marginBlock: "10px",
  },
};

export default function ServicesView() {
  const [itemService, setItemService] = useState([]);
  const { permissions } = useContext(MainContext);

  /**
   *Function to display data
   */
  const listServices = () => {
    GetServices().then((result) => {
      let data = result.data.data;
      let service = [];
      service = data.map((item) => {
        return {
          id: item.id,
          index: item.index,
          name: item.name,
          active: item.active == 0 ? 1 : 0,
          maintenance: item.maintenance,
        };
      });
      setItemService(service);
    });
  };

  /**
   * Function for updating data
   * @param {number} item
   */
  const updateService = (item) => {
    let data = {
      id: item.id,
      maintenance: item.maintenance == 1 ? 0 : 1,
    };
    PutService(data).then((result) => {
      if (result.data.error) {
        console.error(result.data.error.description);
        return;
      }
      listServices();
    });
  };

  useEffect(() => {
    listServices();
  }, []);

  return permissions.admin_services.access ? (
    <div>
      <Row>
        <Col className="mt-2" md={{ span: 6, offset: 3 }}>
          {itemService.map((item) => {
            return (
              <Row
                key={item}
                className="mb-3"
                style={customStylesService.rowContainerService}
              >
                <Col md={8} className="mt-3">
                  <b>{item.name}</b>
                </Col>
                <Col md={4}>
                  <Form
                    className="float-end mt-3"
                    style={{ cursor: "pointer" }}
                  >
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      checked={item.maintenance == 1 ? false : true}
                      onClick={() => updateService(item)}
                    />
                  </Form>
                </Col>
              </Row>
            );
          })}
        </Col>
      </Row>
    </div>
  ) : (
    goTo("/dashboard")
  );
}
