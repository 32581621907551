// crear componente ReportCardView.js
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Col, Container, Row, Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";

import ReportCardBreakdownComponent from "../../components/reportCards/ReportCardBreakdownComponent";
import ReportCardComponent from "../../components/reportCards/ReportCardComponent";
import { decodeValue } from "../../components/global/GlobalTools";
import { GetReportCardPdf } from "../../api/ReportCards";

const customStyles = {
  icon: {
    color: "#B14333",
    fontSize: "24px",
    fontWeight: "bold",
  },
  iconDisabled: {
    color: "#B14333",
    fontSize: "24px",
    fontWeight: "bold",
    cursor: "progress",
    opacity: 0.5,
  },
};

const ReportCardView = () => {
  const params = useParams();
  const studentId = decodeValue(params.student_id);
  const schoolLevelCycleId = decodeValue(params.school_level_cycle);
  // importar traducciones
  const [t] = useTranslation(["global", "reportCards"]);
  const [view, setView] = useState("basic");
  const [gettingPdf, setGettingPdf] = useState(false);
  const [error, setError] = useState("");
  const [studentName, setStudentName] = useState("");

  useEffect(() => {
    if (schoolLevelCycleId) {
      setView("basic");
    }
  }, [studentId, schoolLevelCycleId]);

  const getPdf = (download = false) => {
    setGettingPdf(true);
    const payload = {
      student_id: studentId ? 40914 : 40914,
      justDownload: download,
      // organization_school_level_cycle_id: 345,
      grading_period_ids: ["25055", "25056", "25057", "25058", "25059"],
      studentName: studentName,
    };
    GetReportCardPdf(payload)
      .then((response) => {
        console.log(response);

        if (response.status != 200) {
          setError(t("reportCards:reportCardBasic.problemGettingReportCard"));
          setTimeout(() => {
            setError("");
          }, 3000);
        }
      })
      .finally(() => {
        setGettingPdf(false);
      });
  };

  return (
    <Container fluid>
      <Row>
        <Col md={6}>
          <h2 onClick={() => setView(view == "basic" ? "breakdown" : "basic")}>
            {view === "basic"
              ? t("reportCards:reportCardBasic.title")
              : t("reportCards:reportCardBreakdown.title")}
          </h2>
        </Col>
        {view === "basic" && (
          <Col md={6} className="text-end">
            <i
              className="bi bi-printer-fill me-3"
              role="button"
              style={gettingPdf ? customStyles.iconDisabled : customStyles.icon}
              onClick={gettingPdf ? null : () => getPdf()}
            />
            <i
              className="bi bi-download"
              role="button"
              style={gettingPdf ? customStyles.iconDisabled : customStyles.icon}
              onClick={gettingPdf ? null : () => getPdf(true)}
            />
          </Col>
        )}
      </Row>
      {view === "basic" && (
        <ReportCardComponent setStudentName={setStudentName} />
      )}
      {view === "breakdown" && <ReportCardBreakdownComponent />}
      {error && (
        <Row className="mt-3 justify-content-md-center">
          <Col lg={6} sm={12}>
            <Alert variant="warning">{error}</Alert>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default ReportCardView;
