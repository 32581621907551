import React, { createRef, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Dropzone from "react-dropzone";
import fileDownload from "js-file-download";

// Import API Services
import {
  GetCsvSubconceptsTemplate,
  ImportProducts,
} from "../../../api/Products";

// Import components
import TextLoading from "../../../components/global/TextLoading";

// Set custom Styles
const customStyles = {
  dropzone: {
    position: "relative",
    height: "auto",
    borderWidth: "2px",
    borderColor: "rgb(194, 194, 194)",
    borderStyle: "dashed",
    borderRadius: "5px",
  },
  dropzoneText: {
    fontWeight: "normal",
    fontSize: "14px",
    color: "#ea2c54",
    margin: "20px",
  },
};

export default function ModalImportProducts(props) {
  // Hooks
  const [t] = useTranslation(["products", "global"]);
  const dropzoneRef = createRef();
  const [file, setFile] = useState("");
  const [isDownloadingCsv, setIsDownloadingCsv] = useState(false);
  const [importResult, setImportResult] = useState({});
  const [showModal, setShowModal] = useState(props.show);
  const [showImportResult, setShowImportResult] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  /**
   * Description: Function to download the template to import subconcepts
   */
  const downloadTemplate = () => {
    setIsDownloadingCsv(true);
    GetCsvSubconceptsTemplate()
      .then((result) => {
        if (result?.data) {
          fileDownload(result.data, "subconceptos.csv");
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsDownloadingCsv(false);
      });
  };

  /**
   * Description: Function to load the file
   */
  const openFile = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  /**
   * Description: Function to save file csv
   * @param {File} file
   */
  const saveFile = (file) => {
    if (file.length) {
      setFile(file);
    }
  };

  /**
   * Description: Function to import subconcepts
   */
  const importSubconcepts = () => {
    const payload = new FormData();
    payload.append("file", file[0]);
    payload.append("school_cycle_id", props.schoolCycle?.id);
    setIsSaving(true);
    ImportProducts(payload)
      .then((result) => {
        if (result?.data) {
          const { created, update } = result.data;
          let subconceptsUpdated = [...props.currentSubconcepts];
          // Add to the list of subconcepts those that were added
          if (created?.length > 0) {
            for (const subconceptCreated of created) {
              const isSameConcept =
                subconceptCreated?.product_concept?.id ==
                props.conceptSelected?.id;
              // Add subconcept
              if (isSameConcept) {
                subconceptsUpdated.push(subconceptCreated);
              }
            }
          }
          // Update subconcepts that were modified
          if (update?.length > 0) {
            for (const subconceptUpdated of update) {
              const isSameConcept =
                subconceptUpdated?.product_concept?.id ==
                props.conceptSelected?.id;
              // Find the subconcept position that was modified
              if (isSameConcept) {
                const subconceptIndex = subconceptsUpdated.findIndex(
                  (item) => item.id == subconceptUpdated.id
                );
                // If it was found replace it
                if (subconceptIndex > -1) {
                  subconceptsUpdated[subconceptIndex] = subconceptUpdated;
                }
              }
            }
          }
          // Update list of subconcepts
          props.updateSubconcepts(subconceptsUpdated);
          // Show import result modal
          setImportResult(result.data);
          setShowModal(false);
          setShowImportResult(true);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <>
      {showModal && (
        <Modal show={showModal} onHide={props.onHide} backdrop={"static"}>
          <Modal.Header>
            <Modal.Title>{t("modalImportSubconcepts.title")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-3">
              <Col xs={7}>
                {t("modalImportSubconcepts.downloadTemplateMessage")}
              </Col>
              <Col xs={5}>
                <Button
                  variant="primary"
                  className="float-end"
                  onClick={downloadTemplate}
                  disabled={isDownloadingCsv || isSaving}
                >
                  {isDownloadingCsv ? (
                    <TextLoading
                      text={t("global:buttons.downloading")}
                      variant="light"
                    />
                  ) : (
                    t("modalImportSubconcepts.downloadTemplate")
                  )}
                </Button>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={12}>
                <div style={customStyles.dropzone}>
                  <Dropzone
                    ref={dropzoneRef}
                    accept=".csv, .xlsx, .xls"
                    onDrop={saveFile}
                    noClick
                    noKeyboard
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        className="text-center"
                        id="dropzoneCsv"
                        style={customStyles.dropzoneText}
                        {...getRootProps()}
                      >
                        <div id="fileInformation">
                          <p className="mb-2" id="file Name">
                            {file[0]?.name ||
                              t("modalImportSubconcepts.uploadFilePlaceholder")}
                            <br />
                            {file ? "" : t("global:importCSV.dropFileOr")}
                          </p>
                        </div>
                        <input {...getInputProps()} />
                        <Button
                          id="uploadStudentInformationFileBtn"
                          type="button"
                          className="btn btn-text btn-white"
                          disabled={isDownloadingCsv || isSaving}
                          onClick={openFile}
                        >
                          {t("global:importCSV.uploadFile")}
                        </Button>
                      </div>
                    )}
                  </Dropzone>
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <Button
                variant="secondary"
                onClick={props.onHide}
                disabled={isDownloadingCsv || isSaving}
              >
                {t("global:buttons.cancel")}
              </Button>

              <Button
                variant="primary"
                onClick={importSubconcepts}
                disabled={!file || isDownloadingCsv || isSaving}
              >
                {isSaving ? (
                  <TextLoading
                    text={t("global:buttons.importing")}
                    variant="light"
                  />
                ) : (
                  t("global:buttons.import")
                )}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {/** Modal Success */}
      {showImportResult && (
        <Modal id="modalSuccess" show={showImportResult} backdrop={"static"}>
          <Modal.Header className="modal-header">
            <Modal.Title className="modal-title text-center">
              {t("modalImportSubconcepts.importResult")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body mbottom10">
            <Row className="mb-3">
              <Col md={12}>
                <div className="text-center">
                  {importResult.errors?.length > 0 ? (
                    <i
                      className="far fa-5x fa-times-circle"
                      style={{ color: "#EA2C54" }}
                    />
                  ) : (
                    <i
                      className="far fa-5x fa-check-circle"
                      style={{ color: "#07C25F" }}
                    />
                  )}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12}>
                <div className="fw-bold fs-6">
                  {t("modalImportSubconcepts.importFinished")}
                  {importResult?.errors?.length > 0
                    ? `${t("modalImportSubconcepts.withErrors")} `
                    : ". "}
                  {t("modalImportSubconcepts.recodsCreated") +
                    importResult?.created?.length +
                    ", "}
                  {t("modalImportSubconcepts.recodsUpdated") +
                    importResult?.update?.length +
                    ", "}
                  {t("modalImportSubconcepts.recodsErrors") +
                    importResult?.errors?.length +
                    "."}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12}>
                <p>
                  <a
                    className="float-end fw-bold fs-6"
                    style={{
                      color: "var(--base-color)",
                      textDecoration: "none",
                    }}
                    data-bs-toggle="collapse"
                    href="#DetailsImportProducts"
                    role="button"
                    aria-expanded="false"
                    aria-controls="DetailsImportProducts"
                  >
                    {t("modalImportSubconcepts.viewDetails")}
                  </a>
                </p>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12}>
                <div className="collapse" id="DetailsImportProducts">
                  <div
                    className="card card-body"
                    style={{ background: "var(--white-hover)" }}
                  >
                    {importResult.created && (
                      <div>
                        <p className="fw-bold">
                          {importResult.created.length}{" "}
                          {t("modalImportSubconcepts.recodsCreated")}
                        </p>
                        {importResult.created.map((product, index) => {
                          return (
                            <p key={index}>
                              {`${product.name} - ${product.sku}`}
                            </p>
                          );
                        })}
                      </div>
                    )}
                    {importResult.update && (
                      <div>
                        <p className="fw-bold">
                          {importResult.update.length}{" "}
                          {t("modalImportSubconcepts.recodsUpdated")}
                        </p>
                        {importResult.update.map((product, index) => {
                          return (
                            <p key={index}>
                              {`${product.name} - ${product.sku}`}
                            </p>
                          );
                        })}
                      </div>
                    )}
                    {importResult.errors && (
                      <div>
                        <p className="fw-bold">
                          {importResult.errors.length}{" "}
                          {t("modalImportSubconcepts.recodsErrors")}
                        </p>
                        {importResult.errors.map((error, index) => {
                          return <p key={index}>{error}</p>;
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={props.onHide}>
              {t("global:buttons.accept")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

ModalImportProducts.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  schoolCycle: PropTypes.object.isRequired,
  currentSubconcepts: PropTypes.array.isRequired,
  updateSubconcepts: PropTypes.func.isRequired,
  conceptSelected: PropTypes.object.isRequired,
};
