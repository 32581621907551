import React, { useEffect, useState, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import ExcellentExport from "excellentexport";
import ReactToPrint from "react-to-print";
import PropTypes from "prop-types";
import { MainContext } from "../../App";

//Components
import ModalConfirmDeletedRecords from "../global/modals/ModalConfirmDeletedRecords";
import ModalCreateSuperAdmin from "./ModalCreateSuperAdmin";
import ModalChangePassword from "../global/modals/ModalChangePassword";
import { LoadingTable } from "../lazyLoading/LazyLoading";
import SelectFilter from "../table/SelectFilter";
import ContentTable from "../table/ContentTable";
import ContentSolid from "../global/ContentSolid";
import { Table } from "../table/Table";
import { goTo } from "../global/GlobalTools";

import { GetSuperAdmin, DeleteSuperAdmin } from "../../api/SuperAdmins";

//Design resources
import { Col, Row, Button, Dropdown, Badge } from "react-bootstrap";

//Custom styles section
const stylesCustom = {
  textDangerBold: {
    color: "#ea2c54",
    fontWeight: "bold",
    fontSize: "small",
  },
  textGrayBold: {
    color: "#c8cbcc",
    fontWeight: "bold",
    fontSize: "small",
  },
};
//Actions available on the records
const actionUserOptions = [
  {
    value: "delete",
    label: "superAdmin:view.delete",
  },
  {
    value: "changePassword",
    label: "superAdmin:view.changePassword",
  },
];
//Record reporting options
const actionOptions = [
  { value: "list", label: "superAdmin:view.downloadPDF" },
  { value: "excel", label: "superAdmin:view.downloadExcel" },
];
//Initial structure for a super admin
const inicialSuperAdminValues = {
  name: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
};

/**
 *Component in charge of listing the super administrators and performing actions
 *such as creating/editing as well as generating reports on the list of records.
 * @returns
 */
const SuperAdminView = () => {
  const componentRef = useRef(); //Create the reference for printing
  const { permissions } = useContext(MainContext);
  const [t] = useTranslation(["global", "superAdmin", "reports", "crm"]);
  const [showModalChangePassword, setShowModalChangePassword] = useState(false);
  const [showModalConfirmDeleted, setShowModalConfirmDeleted] = useState(false);
  const [superAdmin, setSuperAdmin] = useState(inicialSuperAdminValues);
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false);
  const [userChangePassword, setUserChangePassword] = useState({});
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [userToDelete, setUserToDelete] = useState([]);
  const [printExport, setPrintExport] = useState(true);
  const [loadingView, setLoadingView] = useState(true);
  const [superAdmins, setSuperAdmins] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [statusOptions, setStatusOptions] = useState([]);

  const [selectAllRows, setSelectAllRows] = useState({
    switch: false,
    value: false,
  });
  const [showModalCreateSuperAdmin, setShowModalCreateSuperAdmin] =
    useState(false);

  /**
   * Get the list of super administrators
   */
  const listSuperAdmin = () => {
    setLoadingView(true);
    setLoading(true);
    GetSuperAdmin({ group_id: 1, limit: 0 }).then((result) => {
      let data = result.data.data;
      setCount(data.length);
      let superAdmin = [];
      superAdmin = data.map((item) => {
        return {
          id: item.id,
          index: item.id,
          name: item.first_name + " " + item.last_name,
          firstName: item.first_name,
          lastName: item.last_name,
          email: item.email || "N/A",
          active: item.active == 1 ? "active" : "inactivo",
        };
      });

      setStatusOptions([
        {
          value: "active",
          label: t("crm:tableCrm.textActive"),
          id: "active",
        },
        {
          value: "inactivo",
          label: t("crm:tableCrm.textInactive"),
          id: "inactivo",
        },
      ]);

      setSuperAdmins(superAdmin);
      setLoading(false);
      setLoadingView(false);
    });
  };

  //table columns
  const columns = React.useMemo(
    () => [
      {
        Header: t("superAdmin:view.tableColName"),
        accessor: "name",
        width: 500,
      },
      {
        Header: t("superAdmin:view.tableColStatus"),
        accessor: "active",
        width: 450,
        Filter: (props) => (
          <SelectFilter defaultValues={statusOptions} {...props} />
        ),
        Cell: ({ cell: { value } }) => {
          return (
            <div>
              {value == "active" ? (
                <Badge pill bg="success">
                  {" "}
                </Badge>
              ) : (
                <Badge pill bg="danger">
                  {" "}
                </Badge>
              )}{" "}
              {value == "active"
                ? t("crm:tableCrm.textActive")
                : t("crm:tableCrm.textInactive")}
            </div>
          );
        },
      },
      {
        Header: t("superAdmin:view.tableColEmail"),
        accessor: "email",
        width: 600,
      },
    ],
    [loading]
  );
  //Saves the data for the table in memory, and is not updated unless useMemo identifies a change in the data
  const data = React.useMemo(() => superAdmins, [loading]);
  //Options to export to excel
  const exportOptions = {
    openAsDownload: true,
    format: "xlsx", //'xlsx' or 'xls' or 'csv'
    filename: t("SuperAdminReport"),
  };
  //Export to Excel
  const downloadFile = (exportOptions, exportData) => {
    ExcellentExport.convert(exportOptions, exportData);
  };

  /**
   * Retrieves a record to modify its information in a modal
   * @param {obj} item table record
   */
  const editSuperAdmin = (item) => {
    const admin = { ...item };
    admin.name = admin.firstName;
    setSuperAdmin(admin);
    setShowModalCreateSuperAdmin(true);
  };

  /**
   * Manages the type of actions available
   * @param {string} action
   */
  const takeAction = (action) => {
    if (action === "changePassword") changePasswordAction();
    if (action === "delete") alertToDeleteSuperAdmins();
  };

  /**
   * Method that controls the flow to change the password to a record
   */
  const changePasswordAction = () => {
    const user = selectedRowIds[0].original;
    setUserChangePassword({
      id: user.id,
      name: user.name + " " + user.lastName,
    });
    setShowModalChangePassword(true);
  };

  /**
   * Method that retrieves ids and names of elements to be deleted
   * Confirmation modal launch
   */
  const alertToDeleteSuperAdmins = () => {
    const itemsConfirm = selectedRowIds.map((row) => {
      return {
        id: row.original.id,
        name: row.original.name,
      };
    });
    setUserToDelete(itemsConfirm);
    setShowModalConfirmDeleted(true);
  };

  /**
   * Method to eliminate super admin
   */
  const confirmDelete = () => {
    let request = {
      user_ids: userToDelete.map((item) => item.id),
    };
    DeleteSuperAdmin(request).then(() => {
      setLoading(true);
      listSuperAdmin();
      setShowModalConfirmDeleted(false);
    });
  };

  /**
   * Method to close modal and reset its values
   */
  const closeModalAdmin = () => {
    setSuperAdmin(inicialSuperAdminValues);
    setShowModalCreateSuperAdmin(false);
  };

  /**
   * Description: Effect to know if all prospects are selected
   */
  useEffect(() => {
    let selectAll = {
      switch: selectAllRows.switch,
      value: isAllRowsSelected,
    };
    setSelectAllRows(selectAll);
  }, [selectedRowIds]);

  /**
   * Initial loading
   */
  useEffect(() => {
    listSuperAdmin();
  }, []);

  return permissions.admin_users.access ? (
    <div>
      <Row className="mb-3">
        <Col md={6}>
          <Button
            variant="outline-secondary"
            onClick={() => {
              let selectAll = {
                switch: !selectAllRows.switch,
                value: !selectAllRows.value,
              };
              setSelectAllRows(selectAll);
            }}
          >
            {t(
              !isAllRowsSelected
                ? "global:buttons.selectAll"
                : "global:buttons.unselectAll"
            )}
          </Button>
          {!!selectedRowIds.length && (
            <Dropdown className="ms-3 d-inline">
              <Dropdown.Toggle
                id="dropdownReportCards"
                variant="outline-secondary"
              >
                <i className="bi bi-person-badge"></i>
                {t("global:buttons.action")}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {actionUserOptions.map((option, index) => {
                  // Hide action change password when more than one is selected
                  if (
                    option.value === "changePassword" &&
                    selectedRowIds.length != 1
                  ) {
                    return;
                  }
                  return (
                    <Dropdown.Item
                      key={index}
                      eventKey={option.value}
                      onClick={() => takeAction(option.value)}
                    >
                      {t(option.label)}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          )}
          {/* Total counter of records and selected records in SuperAdmins table */}
          {selectedRowIds.length ? (
            selectedRowIds.length == 1 ? (
              <p className="ms-3 d-inline" style={stylesCustom.textDangerBold}>
                {t("table:textTableViews.selectedRecord", {
                  countSelected: selectedRowIds.length,
                })}
              </p>
            ) : (
              <p className="ms-3 d-inline" style={stylesCustom.textDangerBold}>
                {t("table:textTableViews.selectedRecords", {
                  countSelected: selectedRowIds.length,
                })}
              </p>
            )
          ) : (
            <p className="ms-3 d-inline" style={stylesCustom.textGrayBold}>
              {t("table:textTableViews.totalRecords", {
                count: count,
              })}
            </p>
          )}
        </Col>
        <Col md={6}>
          <Button
            className="float-end ms-3 "
            variant="primary"
            size="lg"
            onClick={() => setShowModalCreateSuperAdmin(true)}
          >
            Crear super Admin
          </Button>
          <Dropdown className="float-end" style={{ display: "inline-block" }}>
            <Dropdown.Toggle
              id="dropdownReportCards"
              variant="outline-secondary"
              disabled={printExport}
            >
              <i className="bi bi-download"></i>
              {t("global:buttons.download")}
            </Dropdown.Toggle>
            <Dropdown.Menu disabled={printExport}>
              {actionOptions.map((action) => {
                return action.value == "list" ? (
                  <ReactToPrint
                    trigger={() => (
                      <Dropdown.Item>{t(action.label)}</Dropdown.Item>
                    )}
                    content={() => componentRef.current}
                  />
                ) : (
                  <ReactToPrint
                    trigger={() => (
                      <Dropdown.Item>{t(action.label)}</Dropdown.Item>
                    )}
                    content={() => downloadFile(exportOptions, exportData)}
                  />
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      {loadingView ? (
        <ContentSolid>
          <LoadingTable />
        </ContentSolid>
      ) : (
        <ContentTable startColumFilter={1} lastColumFilter={4}>
          <Table
            ref={componentRef}
            columns={columns}
            data={data}
            setExportData={setExportData}
            setPrintExport={setPrintExport}
            rowSelect={true}
            rowOnclick={editSuperAdmin}
            setSelectedRowIds={setSelectedRowIds}
            setIsAllRowsSelected={setIsAllRowsSelected}
            selectAllRows={selectAllRows}
          ></Table>
        </ContentTable>
      )}

      {/* Modals */}
      {showModalCreateSuperAdmin && (
        <ModalCreateSuperAdmin
          showModalCreateSuperAdmin={showModalCreateSuperAdmin}
          closeModalAdmin={closeModalAdmin}
          superAdmin={superAdmin}
          listSuperAdmin={listSuperAdmin}
        />
      )}

      {showModalChangePassword && (
        <ModalChangePassword
          showModalChangePassword={showModalChangePassword}
          setShowModalChangePassword={setShowModalChangePassword}
          user={userChangePassword}
        />
      )}

      {showModalConfirmDeleted && (
        <ModalConfirmDeletedRecords
          showModalConfirmDeleted={showModalConfirmDeleted}
          setShowModalConfirmDeleted={setShowModalConfirmDeleted}
          confirmAction={confirmDelete}
          title={t("superAdmin:modalDeleteSuperAdmin.titleSuperAdminDelete")}
          items={userToDelete}
        />
      )}
    </div>
  ) : (
    goTo("/dashboard")
  );
};
SuperAdminView.propTypes = {
  cell: PropTypes.object,
};
export default SuperAdminView;
