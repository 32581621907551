import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  Button,
  Row,
  Col,
  Dropdown,
  Form,
  Alert,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReleaseGrades } from "../../../api/ReportCards";
import TextLoading from "../../global/TextLoading";

export default function ModalReleaseRatings(props) {
  const [t] = useTranslation(["global", "class"]);
  const [showSelectLevel, setShowSelectLevel] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [levelSelected, setLevelSelected] = useState(null);
  const [errors, setErrors] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Description: Function to release school level grades for the organization's cycle.
   */
  const releaseRatings = () => {
    setErrors(false);
    setIsLoading(true);
    const payload = {
      organization_school_level_cycle_id: levelSelected.value,
    };
    ReleaseGrades(payload)
      .then((response) => {
        if (response.status) {
          setShowConfirm(false);
          setShowSuccess(true);
        } else {
          const error = response?.error;
          setErrors(error?.description);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const nextStep = () => {
    if (showSelectLevel) {
      setShowSelectLevel(false);
      setShowConfirm(true);
    } else if (showConfirm) {
      releaseRatings();
    }
  };
  return (
    <Modal
      show={true}
      bsSize="large"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header>
        <Modal.Title className="modal-title">
          {showSuccess
            ? t("class:modalReleaseRatings.gradesReleased")
            : t("class:modalReleaseRatings.title")}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col md={12}>
            {showSelectLevel && (
              <>
                <Form.Label style={{ marginBottom: "0" }}>
                  {t("class:modalReleaseRatings.selectSchoolLevel")}
                </Form.Label>
                <Dropdown>
                  <Dropdown.Toggle
                    id="schoolLevel"
                    variant="outline-secondary"
                    style={{ width: "100%", textAlign: "inherit" }}
                    bsPrefix="custom-dropdown"
                  >
                    {levelSelected?.label
                      ? levelSelected?.label
                      : t("class:modalReleaseRatings.selectSchoolLevel")}
                    <i
                      className="fa fa-angle-down"
                      style={{ float: "right", marginTop: "2px" }}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ width: "93%" }}>
                    {props.schoolLevels &&
                      props.schoolLevels.map((level) => {
                        return (
                          <Dropdown.Item
                            key={level.value}
                            onClick={() => {
                              setLevelSelected(level);
                            }}
                          >
                            {level.label}
                          </Dropdown.Item>
                        );
                      })}
                  </Dropdown.Menu>
                </Dropdown>
              </>
            )}
            {showConfirm &&
              t("class:modalReleaseRatings.releaseMenssageConfirmation")}
            {showSuccess &&
              t("class:modalReleaseRatings.releaseSuccessMessage")}
          </Col>
        </Row>
        {errors && (
          <Row>
            <Col md={12}>
              <Alert variant="warning" className="mb-0 mt-3">
                {errors}
              </Alert>
            </Col>
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!showSuccess && (
          <Button
            variant="outline-secondary"
            onClick={() => props.setShowModalReleaseRatings(false)}
            disabled={isLoading}
          >
            {t("global:buttons.cancel")}
          </Button>
        )}
        <Button
          variant={showSuccess ? "outline-secondary" : "primary"}
          onClick={
            !showSuccess
              ? nextStep
              : () => props.setShowModalReleaseRatings(false)
          }
          disabled={!levelSelected || isLoading}
        >
          {isLoading ? (
            <TextLoading
              text={t("class:modalReleaseRatings.releasing")}
              variant="light"
            />
          ) : !showSuccess ? (
            t("global:buttons.accept")
          ) : (
            t("global:buttons.close")
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ModalReleaseRatings.propTypes = {
  setShowModalReleaseRatings: PropTypes.func, //Required
  schoolLevels: PropTypes.array, //Required
};
