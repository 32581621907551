/**
 * Routes
 *
 * Purpose: Contains the url of the pages
 *
 * @author Marisol Torres <marisol@inclancommunications.com>
 * @date 01/November/2021 12:51:25 PM
 */

import DashboardLayout from "./components/DashboardLayout";
import DashboardView from "./pages/DashboardView";
import PaymentsView from "./pages/payments/PaymentsView";
import StudentReportView from "./pages/students/StudentReportView";
import ProspectListView from "./pages/crm/ProspectListView";
import ReportCardEditor from "./pages/reportCards/ReportCardEditor";
import ReportCardsList from "./pages/reportCards/ReportCardsList";
import RelativeView from "./pages/relatives/RelativeView";
import DownloadZipView from "./pages/DownloadZipView";
import ExtraLinkPage from "./pages/extraLink/ExtraLinkPage";
import StaffListView from "./pages/staff/StaffListView";
import AdminView from "./pages/admin/AdminView";
import ClassesAndSubjects from "./pages/classesAndSubjects/MainView";
import ScholarshipDetails from "./pages/Scholarships/ScholarshipDetails";
import MainNews from "./pages/news/MainNews";
import CaptureGrades from "./pages/captureGrades/CaptureGradesView";
import ReportCardView from "./pages/reportCards/ReportCardView";
import ConfirmTransferView from "./pages/ConfirmTransferView";

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    routes: [
      {
        path: "/dashboard",
        component: DashboardView,
      },
      {
        path: "/payments/:view",
        component: PaymentsView,
      },
      {
        path: "/payments",
        component: PaymentsView,
      },
      {
        path: "/v2/students",
        component: StudentReportView,
      },
      {
        path: "/students",
        component: StudentReportView,
      },
      {
        path: "/admin/:view",
        component: AdminView,
      },
      {
        path: "/admin",
        component: AdminView,
      },
      {
        path: "/crm/:leads",
        component: ProspectListView,
      },
      {
        path: "/settings/report_cards/:id",
        component: ReportCardEditor,
      },
      {
        path: "/settings/report_cards",
        component: ReportCardsList,
      },
      {
        path: "/relatives",
        component: RelativeView,
      },
      {
        path: "/download_zip",
        component: DownloadZipView,
      },
      {
        path: "/app",
        component: ExtraLinkPage,
      },
      {
        path: "/staff",
        component: StaffListView,
      },
      {
        path: "/class",
        component: ClassesAndSubjects,
      },
      {
        path: "/subjects",
        component: ClassesAndSubjects,
      },
      {
        path: "/scores",
        component: ClassesAndSubjects,
      },
      {
        path: "/development_areas",
        component: ClassesAndSubjects,
      },
      {
        path: "/scholarship/new",
        component: ScholarshipDetails,
      },
      {
        path: "/scholarship/:id",
        component: ScholarshipDetails,
      },
      {
        path: "/news/:view",
        component: MainNews,
      },
      {
        path: "/news",
        component: MainNews,
      },
      {
        path: "/capture_grades",
        component: CaptureGrades,
      },
      {
        path: "/report_card/:student_id/:school_level_cycle",
        component: ReportCardView,
      },
      {
        path: "/confirm_transfer",
        component: ConfirmTransferView,
      },
    ],
  },
  {
    path: "/v2",
    component: AdminView,
    routes: [
      {
        path: "/dashboard",
        component: StudentReportView,
      },
    ],
  },
];

export default routes;
