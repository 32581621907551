import { React, useEffect, useState, useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Button, Row, Col, Spinner, Form, Alert } from "react-bootstrap";
import PropTypes from "prop-types";
import { Form as FormOfFormik, Formik } from "formik";
import * as Yup from "yup";
import FieldInput from "../../global/form/FieldInput";
import { DatePicker } from "../../global/form/DatePicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import FormInputLeftDropdown from "../../global/form/FormInputLeftDropdown";
import FormCheck from "../../global/form/FormCheck";
import FormSelect from "../../global/form/FormSelect";
import Cookies from "js-cookie";
import { MainContext } from "../../../App";

import {
  GetRfcIssuer,
  GenerateSku,
  GetSubconcept,
  SaveSubconcept,
} from "../../../api/Products";
import ModalSuccess from "../../global/modals/ModalSuccess";
import { addDays, subDays } from "date-fns";
import moment from "moment";

// Custom styles
const customStyles = {
  prepaidContainer: {
    marginLeft: "0px",
    marginRight: "0px",
    background: "#fafafa",
    border: "1px solid #D5DADB",
    // borderColor: "#d4d4d4",
    borderRadius: "5px",
    padding: "20px 15px",
    marginBottom: "20px",
  },
  iconPlus: {
    marginLeft: "5px",
    color: "#247048",
    fontSize: "17px",
    cursor: "pointer",
  },
  iconTrash: {
    marginLeft: "5px",
    // color: "#247048",
    fontSize: "17px",
    cursor: "pointer",
    paddingTop: "8px",
  },
};

export default function ModalSubconcept(props) {
  const prefix = process.env.REACT_APP_PREFIX;
  // Get cookie language
  const language = Cookies.get(`cmLanguage${prefix}`);
  const isSpanish = language === "spanish";
  // Late fee recurrence
  const surchargePeriods = [
    { value: "monthly", label: isSpanish ? "Mensual" : "Monthly" },
    { value: "biweekly", label: isSpanish ? "Catorcenal" : "Biweekly" },
    { value: "weekly", label: isSpanish ? "Semanal" : "Weekly" },
    { value: "daily", label: isSpanish ? "Diario" : "Daily" },
    { value: "once", label: isSpanish ? "Una vez" : "Once" },
  ];
  // Subconcept template
  const base = {
    currency_id: "100",
    name: "",
    sku: "",
    price: "",
    issuer_rfc_id: "",
    tax_percentage: "",
    early_payment_dates: [
      {
        amount: "",
        end_date: "",
        bank_account_id: "",
      },
    ],
    penalty_type: "flat_fee",
    penalty: "",
    surcharge_end_date: "",
    surcharge_bank_account_id: "",
    add_IVA_surcharge: false,
    tax_percentage_surcharge: "",
    penalty_period: "once",
    description: "",
  };
  // Hooks
  const { permissions } = useContext(MainContext);
  const [t] = useTranslation(["global", "products"]);
  const [showModal, setShowModal] = useState(props.showSubconceptModal);
  const [loading, setLoading] = useState(false);
  const [subconceptInfo, setSubconceptInfo] = useState(base);
  const [sapIsActive, setSapIsActive] = useState(false);
  const [rfcsIssuerList, setRfcsIssuerList] = useState([]);
  const [typingCallbackName, setTypingCallbackName] = useState(null);
  const [generatingSku, setGeneratingSku] = useState(false);
  const [errors, setErrors] = useState(false);
  const [reload, setReload] = useState(false);
  const [hideIvaField, setHideIvaField] = useState(false);
  const [showIvaSurcharge, setShowIvaSurcharge] = useState(false);
  const [showModalSuccess, setShowSuccessModal] = useState(false);

  registerLocale("spanish", es);
  let Locallanguage = {};
  if (isSpanish) {
    Locallanguage = { locale: "spanish" };
  }

  /* Initial Loading */
  useEffect(() => {
    getRfcIssuer();
    if (props.subconcept?.id) {
      getSubconcept(props.subconcept);
    }
  }, []);

  useEffect(() => {
    // Validate if the vat check is shown in normal charge
    const cfdiUseCode = props.conceptSelected?.cfdi_use?.cfdi_use_code || false;
    const hasVatActivated = props.conceptSelected?.add_IVA_subconcept == "1";
    setHideIvaField(cfdiUseCode == "D10" && !hasVatActivated);

    // Validate if the vat is shown in late charge
    setShowIvaSurcharge(cfdiUseCode == "D10");

    // Validate if sap is active
    const services = JSON.parse(localStorage.getItem(`${prefix}Services`));
    const sapIsActive =
      services.SAP && services.SAP.active && permissions.sap_reports?.edit;
    setSapIsActive(sapIsActive);
  }, []);

  const getSubconcept = (subconcept) => {
    const payload = {
      id: subconcept.id,
      product_concept_id: subconcept.product_concept_id,
      school_cycle_id: props.cycleSelected.id,
    };
    setReload(true);
    GetSubconcept(payload).then((response) => {
      if (response.status) {
        let subconcept = response.data;
        subconcept.add_IVA_surcharge =
          subconcept.add_IVA_surcharge == 1 ? true : false;
        subconcept.penalty =
          subconcept.penalty && subconcept.penalty != 0
            ? subconcept.penalty
            : "";
        subconcept.penalty_type = subconcept.penalty_type || "flat_fee";
        subconcept.surcharge_end_date = subconcept.surcharge_end_date
          ? // Convert string date to date
            new Date(subconcept.surcharge_end_date.replace(" ", "T"))
          : "";

        if (subconcept.early_payment_dates.length > 0) {
          subconcept.early_payment_dates = subconcept.early_payment_dates.map(
            (earlyPayment) => {
              // Convert string date to date
              if (earlyPayment.end_date) {
                const date = new Date(
                  moment
                    .utc(earlyPayment.end_date, "YYYY-MM-DD h:m:s")
                    .local()
                    .startOf("day")
                );
                earlyPayment.end_date = date;
              }
              return earlyPayment;
            }
          );
        }
        setSubconceptInfo(subconcept);

        setTimeout(() => {
          setReload(false);
        }, 0);
      }
    });
  };

  const getRfcIssuer = () => {
    const payload = {
      organization_id: Cookies.get(`cmOrganizationID${prefix}`),
    };
    GetRfcIssuer(payload).then((response) => {
      if (response.status) {
        let rfcsIssuer = [];
        for (const RFC of response.data) {
          let rfcObj = {};
          let isOverflow = false;
          let label = "";
          isOverflow = RFC.issuer_name.length > 21;
          label = RFC.issuer_rfc + " - " + RFC.issuer_name.substring(0, 22);
          rfcObj.value = RFC.id;
          rfcObj.label = isOverflow ? label + "..." : label;
          rfcsIssuer.push(rfcObj);
        }
        setRfcsIssuerList(rfcsIssuer);
      }
    });
  };

  const getGenerateCode = (field, value, formik) => {
    if (field === "name") {
      setGeneratingSku(true);
    }

    let payload = {
      school_cycle_id: props.cycleSelected.id,
    };
    payload[field] = value;
    GenerateSku(payload)
      .then((response) => {
        if (response.status) {
          formik.setFieldValue("sku", response.data.sku);
        } else {
          setErrors(response);
        }
      })
      .finally(() => {
        setGeneratingSku(false);
      });
  };

  const disableSaveButton = (values) => {
    let disable = false;

    // verificar que name, price, issuer_rfc_id no esten vacios
    disable =
      values.name == "" ||
      values.sku == "" ||
      values.price == "" ||
      values.issuer_rfc_id == "";

    return disable;
  };

  const save = (values) => {
    let payload = {
      ...values,
      name: values.name?.toUpperCase(),
      school_cycle_id: props.cycleSelected.id,
      product_concept_id: props.productConceptId,
      tax_percentage: values.tax_percentage ? values.tax_percentage : "0",
      tax_percentage_surcharge: values.tax_percentage_surcharge
        ? values.tax_percentage_surcharge
        : "0",
    };

    // Remove objects from early_payment_dates if they do not have at least amount and end_date or end_date is old
    payload.early_payment_dates = values.early_payment_dates
      ?.filter(({ amount, end_date }) => {
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        const endDateObj = new Date(end_date);

        return (
          amount !== "" &&
          end_date !== "" &&
          endDateObj.getTime() >= date.getTime()
        );
      })
      ?.map((earlyPayment) => {
        const date = new Date(earlyPayment.end_date);
        date.setHours(23, 59, 59, 999);
        // Remove bank_account_id if sap is inactive
        if (!sapIsActive) {
          delete earlyPayment.bank_account_id;
        }
        return {
          ...earlyPayment,
          end_date: Math.floor(new Date(date).getTime() / 1000),
        };
      });

    // Remove all empty and null fields from values
    if (!payload.id) {
      Object.keys(payload).forEach((key) => {
        if (payload[key] === "" || payload[key] === null) {
          delete payload[key];
        }
      });
    }

    // Reset penalty does not exist
    if (!payload.penalty) {
      payload.penalty = 0;
      payload.penalty_type = "flat_fee";
    }
    if (values.surcharge_end_date && values.surcharge_end_date != "") {
      // Convert date to timestamp
      let date = new Date(payload.surcharge_end_date);
      payload.surcharge_end_date = date.getTime() / 1000;
    } else {
      payload.surcharge_end_date = 0;
    }
    // Clean penalty data if the date has passed.
    if (values.surcharge_end_date && values.surcharge_end_date < new Date()) {
      delete payload.surcharge_end_date;
      delete payload.penalty_type;
      delete payload.penalty;
    }
    // Remove surcharge_bank_account_id if sap is inactive
    if (!sapIsActive) {
      delete payload.surcharge_bank_account_id;
    }

    setLoading(true);
    SaveSubconcept(payload)
      .then((response) => {
        if (response.status) {
          const newSubconcept = values.id ? false : true;
          updateSubconceptList(response.data, newSubconcept);
        } else {
          setErrors(response);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateSubconceptList = (data, newSubconcept) => {
    setShowModal(false);
    setShowSuccessModal(true);
    if (newSubconcept) {
      // Insert at the end of the list
      let newSubconcepts = props.subconcepts;
      newSubconcepts.push(data);
      props.setSubconceptSelected(data);
    } else {
      // Find the position of the subconcept in the list
      let subconceptIndex = props.subconcepts.findIndex(
        (subconcept) => subconcept.id === data.id
      );
      // Replace the subitem in the list
      let newSubconcepts = props.subconcepts;
      newSubconcepts[subconceptIndex] = data;
      props.setSubconceptSelected(data);
    }
  };

  const validateSchema = Yup.object().shape({
    name: Yup.string().required(
      t("products:modalSubconcept.subconceptName") +
        " " +
        t("global:isRequired")
    ),
    sku: Yup.string().required(
      t("products:modalSubconcept.sku") + " " + t("global:isRequired")
    ),
    price: Yup.string().required(
      t("products:modalSubconcept.normalFee") + " " + t("global:isRequired")
    ),
    issuer_rfc_id: Yup.string()
      .required(
        t("products:modalSubconcept.RFCtoBill") + " " + t("global:isRequired")
      )
      .nullable(),
    tax_percentage: Yup.string().test(
      "validate-iva",
      t("products:modalSubconcept.taxValidateMenssage"),
      (value) => {
        if (value) {
          let taxRegex = new RegExp(/^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/);
          return taxRegex.test(value) || value === "";
        } else {
          return true;
        }
      }
    ),
    // surcharge_end_date validar para que sea requerido cuando penalty sea diferente de vacio
    surcharge_end_date: Yup.string().when("penalty", {
      is: (penaltyValue) => {
        return penaltyValue != undefined && penaltyValue != "";
      },
      then: Yup.string()
        .required(t("products:modalSubconcept.deadlineSurchargeIsRequired"))
        .nullable(),
      otherwise: Yup.string().nullable(),
    }),
    // Prompt payment array
    early_payment_dates: Yup.array().of(
      Yup.object().shape({
        amount: Yup.string()
          .nullable()
          .test(
            "amount-required-if-end_date",
            t("products:modalSubconcept.amountPaidIsRequired"),
            function (value) {
              const { end_date } = this.parent;
              return end_date ? !!value : true;
            }
          ),
        end_date: Yup.string()
          .nullable()
          .test(
            "end_date-required-if-amount",
            t("products:modalSubconcept.deadlineIsRequired"),
            function (value) {
              const { amount } = this.parent;
              return amount ? !!value : true;
            }
          ),
      })
    ),
    tax_percentage_surcharge: Yup.string().when("add_IVA_surcharge", {
      is: (addIvaSurcharge) => {
        return addIvaSurcharge != undefined && addIvaSurcharge != "";
      },
      then: Yup.string()
        .nullable()
        .required(
          t("products:modalSubconcept.ivaForLateFee") +
            " " +
            t("global:isRequired")
        ),
      otherwise: Yup.string().nullable(),
    }),
  });

  /**
   * Description: Function to format quantity fields
   * @param {string} field
   * @param {string} inputValue
   * @param {object} formik
   */
  const handleAmountChange = (field, inputValue, formik) => {
    const decimalCount = (inputValue.match(/\./g) || []).length;
    if (decimalCount <= 1) {
      const value = inputValue.replace(/[^0-9.]/g, "");
      formik.setFieldValue(field, value);
    }
  };

  /**
   * Description: Function to format percentage fields
   * @param {string} field
   * @param {string} inputValue
   * @param {object} formik
   */
  const handleAmountPercentage = (field, inputValue, formik) => {
    const decimalCount = (inputValue.match(/\./g) || []).length;
    if (decimalCount <= 1) {
      let value = inputValue.replace(/[^0-9.]/g, "");
      if (value !== "" && parseFloat(value) > 100) {
        value = "100";
      }
      formik.setFieldValue(field, value);
    }
  };

  /**
   * Description: Function to clean deadline surcharge fields
   * @param {object} formik
   * @param {object} values
   */
  const cleanDeadlineSurcharge = async (formik, values) => {
    await formik.setValues({
      ...values,
      penalty: "",
      surcharge_end_date: "",
      add_IVA_surcharge: false,
      tax_percentage_surcharge: "",
      penalty_type: "flat_fee",
    });
    await formik.setFieldTouched("surcharge_end_date", true);
  };

  return (
    <>
      {showModal && (
        <Modal
          show={showModal}
          size="lg"
          aria-labelledby="contained-modal-title-lg"
          onHide={props.onHide}
          backdrop={"static"}
        >
          <Modal.Header>
            <Modal.Title className="modal-title">
              {props.subconcept
                ? t("products:modalSubconcept.editSubconcept")
                : t("products:modalSubconcept.title")}
            </Modal.Title>
          </Modal.Header>
          {!reload ? (
            <Formik
              initialValues={subconceptInfo}
              validationSchema={validateSchema}
              validateOnMount={true}
              onSubmit={(values) => {
                save(values);
              }}
            >
              {({ values, ...formik }) => (
                <FormOfFormik>
                  <Modal.Body>
                    <Row>
                      <Col md={12} className=" mb-2">
                        <FieldInput
                          label={t("products:modalSubconcept.subconceptName")}
                          complementaryLabel={t(
                            "products:modalSubconcept.subconceptNameLabel"
                          )}
                          capitalize={true}
                          name="name"
                          type="text"
                          placeholder={t(
                            "products:modalSubconcept.subconceptLabelName"
                          )}
                          required={true}
                          showRequired={true}
                          onChange={(event) => {
                            formik.setFieldValue("name", event.target.value);
                            setErrors(false);
                            // Generate sku from sub item name
                            if (typingCallbackName) {
                              clearTimeout(typingCallbackName);
                            }
                            let typingName = setTimeout(() => {
                              event.target.value &&
                                getGenerateCode(
                                  "name",
                                  event.target.value,
                                  formik
                                );
                            }, 500);
                            setTypingCallbackName(typingName);
                          }}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12} className=" mb-2">
                        <FieldInput
                          label={t("products:modalSubconcept.sku")}
                          name="sku"
                          type="text"
                          required={true}
                          showRequired={true}
                          placeholder={
                            generatingSku
                              ? t("products:modalSubconcept.generatingSku")
                              : "SKU"
                          }
                          onChange={(event) => {
                            formik.setFieldValue("sku", event.target.value);
                            setErrors(false);
                            // Generate sku from sub item name
                            if (typingCallbackName) {
                              clearTimeout(typingCallbackName);
                            }
                            let typingName = setTimeout(() => {
                              event.target.value &&
                                getGenerateCode(
                                  "sku",
                                  event.target.value,
                                  formik
                                );
                            }, 500);
                            setTypingCallbackName(typingName);
                          }}
                          value={generatingSku ? "" : values.sku}
                          disabled={generatingSku}
                          endIcon={
                            generatingSku ? (
                              <span className="fas fa-circle-notch fa-spin" />
                            ) : null
                          }
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6} className=" mb-2">
                        <FieldInput
                          label={t("products:modalSubconcept.normalFee")}
                          capitalize={true}
                          name="price"
                          type="text"
                          placeholder="$"
                          required={true}
                          showRequired={true}
                          onChange={(e) =>
                            handleAmountChange("price", e.target.value, formik)
                          }
                        />
                      </Col>
                      <Col md={6} className={hideIvaField ? "d-none" : ""}>
                        <FormSelect
                          label={t("products:modalSubconcept.iva")}
                          name="tax_percentage"
                          className="mb-0"
                          options={[
                            {
                              value: "",
                              label: "0%",
                            },
                            {
                              value: "8",
                              label: "8 %",
                            },
                            {
                              value: "16",
                              label: "16 %",
                            },
                          ]}
                          isSearchable={false}
                          onChange={(country) => {
                            formik.setFieldValue(
                              "tax_percentage",
                              country.value
                            );
                          }}
                          value={
                            values.tax_percentage &&
                            values.tax_percentage !== ""
                              ? {
                                  value: values.tax_percentage,
                                  label: values.tax_percentage + " %",
                                }
                              : ""
                          }
                          placeholder="0.00 %"
                        />

                        <Form.Text className="text-muted">
                          {t("products:modalSubconcept.ivaLabel")}
                        </Form.Text>
                      </Col>
                    </Row>

                    {/* Pronto pago */}
                    <Row className="mt-3">
                      <Col md={6} className="mb-2">
                        <Form.Label>
                          {t("products:modalSubconcept.prepaid")}
                        </Form.Label>
                        <Form.Text className="text-muted">
                          {" ( " +
                            t(
                              "products:modalSubconcept.beforePrepaymentDeadline"
                            ) +
                            " ) "}
                        </Form.Text>
                      </Col>
                      <Col md={6} className="mb-2 float-end">
                        <i
                          className="bi bi-plus-circle-fill float-end"
                          style={customStyles.iconPlus}
                          onClick={() => {
                            formik.setFieldValue("early_payment_dates", [
                              ...values.early_payment_dates,
                              {
                                amount: "",
                                end_date: "",
                                bank_account_id: "",
                              },
                            ]);
                          }}
                        />
                        <span className="fw-light float-end mt-1">
                          {t("products:modalSubconcept.addChronologicalDates")}
                        </span>
                      </Col>
                    </Row>
                    <Row style={customStyles.prepaidContainer}>
                      {values.early_payment_dates.map((earlyPayment, index) =>
                        !earlyPayment.id ||
                        (earlyPayment.id && earlyPayment.active) ? (
                          <Fragment key={index}>
                            <Col md={sapIsActive ? 3 : 6} className="mb-2">
                              <FieldInput
                                label={t(
                                  "products:modalSubconcept.amountPayable"
                                )}
                                name={
                                  "early_payment_dates[" + index + "].amount"
                                }
                                type="text"
                                placeholder="$"
                                value={earlyPayment.amount}
                                onChange={(e) =>
                                  handleAmountChange(
                                    "early_payment_dates[" + index + "].amount",
                                    e.target.value,
                                    formik
                                  )
                                }
                                onInput={(event) => {
                                  const value = event.target.value;
                                  if (!value && !earlyPayment.end_date) {
                                    formik.setFieldValue(
                                      "early_payment_dates[" +
                                        index +
                                        "].bank_account_id",
                                      ""
                                    );
                                  }
                                  formik.setFieldTouched(
                                    "early_payment_dates[" +
                                      index +
                                      "].end_date",
                                    true,
                                    false
                                  );
                                }}
                              />
                            </Col>
                            <Col md={sapIsActive ? 4 : 6} className="mb-2">
                              <Row>
                                <Col md={sapIsActive ? 12 : 11}>
                                  <DatePicker
                                    label={t(
                                      "products:modalSubconcept.deadline"
                                    )}
                                    placeholderText="DD/MM/YYYY"
                                    dateFormat="dd/MM/yyyy"
                                    name={
                                      "early_payment_dates[" +
                                      index +
                                      "].end_date"
                                    }
                                    minDate={
                                      values.early_payment_dates?.[index - 1]
                                        ?.end_date
                                        ? addDays(
                                            values.early_payment_dates[
                                              index - 1
                                            ].end_date,
                                            1
                                          )
                                        : new Date()
                                    }
                                    maxDate={
                                      values.early_payment_dates?.[index + 1]
                                        ?.end_date
                                        ? subDays(
                                            values.early_payment_dates[
                                              index + 1
                                            ].end_date,
                                            1
                                          )
                                        : values.surcharge_end_date
                                        ? subDays(values.surcharge_end_date, 1)
                                        : ""
                                    }
                                    excludeDates={
                                      values.early_payment_dates
                                        ? values.early_payment_dates.map(
                                            (item) =>
                                              item.end_date ||
                                              (item.id && item.active == 0)
                                          )
                                        : ""
                                    }
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    {...Locallanguage}
                                    cleanButton={true}
                                    onClean={() => {
                                      formik.setFieldValue(
                                        "early_payment_dates[" +
                                          index +
                                          "].end_date",
                                        ""
                                      );
                                      formik.setFieldTouched(
                                        "early_payment_dates[" +
                                          index +
                                          "].amount",
                                        true,
                                        false
                                      );
                                      formik.setFieldTouched(
                                        "early_payment_dates[" +
                                          index +
                                          "].end_date",
                                        true,
                                        false
                                      );
                                    }}
                                    cleanButtonTooltip={t(
                                      "products:modalSubconcept.earlyPaymentEndDateTooltip"
                                    )}
                                    value={earlyPayment.end_date}
                                    onSelect={(date) => {
                                      formik.setFieldValue(
                                        "early_payment_dates[" +
                                          index +
                                          "].end_date",
                                        date
                                      );
                                      formik.setFieldTouched(
                                        "early_payment_dates[" +
                                          index +
                                          "].amount",
                                        true,
                                        false
                                      );
                                    }}
                                    formGroupClassName="subconcept-modal mb-3"
                                  />
                                </Col>
                                {!sapIsActive && (
                                  <Col md={1}>
                                    <i
                                      className="bi bi-trash-fill float-end mt-4"
                                      style={customStyles.iconTrash}
                                      onClick={() => {
                                        let newEarlyPaymentDates =
                                          values.early_payment_dates;
                                        if (newEarlyPaymentDates[index]?.id) {
                                          newEarlyPaymentDates[
                                            index
                                          ].active = 0;
                                        } else {
                                          newEarlyPaymentDates.splice(index, 1);
                                        }
                                        formik.setFieldValue(
                                          "early_payment_dates",
                                          newEarlyPaymentDates
                                        );
                                      }}
                                    />
                                  </Col>
                                )}
                              </Row>
                            </Col>
                            {sapIsActive && (
                              <Col md={5} className="mb-2">
                                <Row>
                                  <Col md={11}>
                                    <FieldInput
                                      label={t(
                                        "products:modalSubconcept.accountingAccountID"
                                      )}
                                      name="sap"
                                      type="text"
                                      placeholder={t(
                                        "products:modalSubconcept.accountingAccountID"
                                      )}
                                      value={earlyPayment.bank_account_id}
                                      onChange={(event) => {
                                        const { value } = event.target;
                                        const isValidInput =
                                          /^[0-9a-zA-Z]*$/.test(value);
                                        if (isValidInput) {
                                          formik.setFieldValue(
                                            "early_payment_dates[" +
                                              index +
                                              "].bank_account_id",
                                            value.replace(/\s+/g, "")
                                          );
                                        } else {
                                          formik.setFieldValue(
                                            "early_payment_dates[" +
                                              index +
                                              "].bank_account_id",
                                            value.slice(0, -1)
                                          );
                                        }
                                      }}
                                      maxLength={100}
                                      disabled={
                                        earlyPayment.amount == "" &&
                                        earlyPayment.end_date == ""
                                      }
                                    />
                                  </Col>
                                  <Col md={1}>
                                    <i
                                      className="bi bi-trash-fill float-end mt-4"
                                      style={customStyles.iconTrash}
                                      onClick={() => {
                                        let newEarlyPaymentDates =
                                          values.early_payment_dates;
                                        if (newEarlyPaymentDates[index]?.id) {
                                          newEarlyPaymentDates[
                                            index
                                          ].active = 0;
                                        } else {
                                          newEarlyPaymentDates.splice(index, 1);
                                        }
                                        formik.setFieldValue(
                                          "early_payment_dates",
                                          newEarlyPaymentDates
                                        );
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            )}
                          </Fragment>
                        ) : (
                          ""
                        )
                      )}
                      {/* no early payments */}
                      {values.early_payment_dates.every(
                        (earlyPayment) =>
                          earlyPayment.id && !earlyPayment.active
                      ) && (
                        <Col md={12} className="mb-2">
                          {t(
                            "products:modalSubconcept.noBeforePrepaymentDeadlineDates"
                          )}
                        </Col>
                      )}
                    </Row>
                    {/* Cargo moratorio */}
                    <Row>
                      <Col
                        md={sapIsActive ? 4 : 6}
                        className="mb-2 subconcept-modal"
                      >
                        <FormInputLeftDropdown
                          label={t("products:modalSubconcept.lateFee")}
                          complementaryLabel={t(
                            "products:modalSubconcept.idExceedDeadline"
                          )}
                          name="penalty"
                          placeholder={
                            values.penalty_type == "flat_fee"
                              ? "$ 0.00"
                              : "0.00 %"
                          }
                          dropdownlabel={
                            values.penalty_type == "flat_fee" ? "$" : "%"
                          }
                          dropdownfield="penalty_type"
                          dropdownmenu={[
                            { value: "flat_fee", field: "$" },
                            { value: "percentage", field: "%" },
                          ]}
                          dropdownOnClick={() => {
                            if (
                              values.penalty_type == "flat_fee" &&
                              values.penalty > 100
                            ) {
                              formik.setFieldValue("penalty", "");
                            }
                          }}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (values.penalty_type == "flat_fee") {
                              handleAmountChange("penalty", value, formik);
                            } else {
                              handleAmountPercentage("penalty", value, formik);
                            }
                          }}
                          onInput={(event) => {
                            const value = event.target.value;
                            if (!value) {
                              formik.setFieldValue("surcharge_end_date", "");
                              formik.setFieldValue(
                                "surcharge_bank_account_id",
                                ""
                              );
                              formik.setFieldValue("add_IVA_surcharge", false);
                              formik.setFieldValue(
                                "tax_percentage_surcharge",
                                ""
                              );
                            }
                            formik.setFieldTouched("surcharge_end_date", true);
                          }}
                          formGroupClassName="subconcept-modal mb-3"
                          disabled={
                            values.surcharge_end_date &&
                            new Date() >= values.surcharge_end_date
                          }
                        />
                      </Col>
                      <Col md={sapIsActive ? 4 : 6} className="">
                        <DatePicker
                          placeholderText="DD/MM/YYYY"
                          dateFormat="dd/MM/yyyy"
                          name="surcharge_end_date"
                          required={values.penalty}
                          showRequired={values.penalty}
                          label={t(
                            "products:modalSubconcept.deadlineWithoutSurcharge"
                          )}
                          stylesLabel={
                            sapIsActive
                              ? values.penalty
                                ? { marginBottom: "25px" }
                                : { marginBottom: "29px" }
                              : {}
                          }
                          minDate={
                            values.early_payment_dates?.at(-1)?.end_date &&
                            values.early_payment_dates?.at(-1)?.end_date >=
                              new Date()
                              ? addDays(
                                  values.early_payment_dates.at(-1).end_date,
                                  1
                                )
                              : new Date(
                                  new Date().getTime() + 24 * 60 * 60 * 1000
                                )
                          }
                          cleanButton={true}
                          onChange={async (date) => {
                            if (date) {
                              formik.setFieldValue("surcharge_end_date", date);
                            } else {
                              cleanDeadlineSurcharge(formik, values);
                            }
                          }}
                          onClean={() => cleanDeadlineSurcharge(formik, values)}
                          cleanButtonTooltip={t(
                            "products:modalSubconcept.surchargeEndDateTooltip"
                          )}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          {...Locallanguage}
                          formGroupClassName="subconcept-modal mb-3 "
                        />
                      </Col>
                      {sapIsActive && (
                        <Col md={4} className="mb-2">
                          <Form.Label style={{ marginBottom: "29px" }}>
                            {t("products:modalSubconcept.accountingAccountID")}
                          </Form.Label>
                          <FieldInput
                            name="surcharge_bank_account_id"
                            type="text"
                            placeholder={t(
                              "products:modalSubconcept.accountingAccountID"
                            )}
                            onChange={(event) => {
                              const { value } = event.target;
                              const isValidInput = /^[0-9a-zA-Z]*$/.test(value);
                              if (isValidInput) {
                                formik.setFieldValue(
                                  "surcharge_bank_account_id",
                                  value.replace(/\s+/g, "")
                                );
                              } else {
                                formik.setFieldValue(
                                  "surcharge_bank_account_id",
                                  value.slice(0, -1)
                                );
                              }
                            }}
                            maxLength={100}
                            disabled={!values.penalty}
                          />
                        </Col>
                      )}
                    </Row>

                    <Row className={showIvaSurcharge ? "" : "d-none"}>
                      {/**add_IVA_surcharge*/}
                      <Col md={6}>
                        <FormCheck
                          name="add_IVA_surcharge"
                          label={t("products:modalSubconcept.applyVatLateFee")}
                          type="checkbox"
                          style={{ marginTop: "37px", fontSize: "14px" }}
                          onClick={async () => {
                            await formik.setFieldValue(
                              "add_IVA_surcharge",
                              !values.add_IVA_surcharge
                            );
                            await formik.setFieldValue(
                              "tax_percentage_surcharge",
                              ""
                            );
                            await formik.setFieldTouched(
                              "tax_percentage_surcharge",
                              true
                            );
                          }}
                          disabled={
                            !values.penalty ||
                            !values.surcharge_end_date ||
                            (values.surcharge_end_date &&
                              new Date() >= values.surcharge_end_date)
                          }
                          checked={values.add_IVA_surcharge}
                        />
                      </Col>
                      {values.add_IVA_surcharge && (
                        <Col md={6}>
                          <FormSelect
                            required={values.add_IVA_surcharge}
                            showRequired={values.add_IVA_surcharge}
                            label={t("products:modalSubconcept.ivaForLateFee")}
                            name="tax_percentage_surcharge"
                            options={[
                              {
                                value: "8",
                                label: "8 %",
                              },
                              {
                                value: "16",
                                label: "16 %",
                              },
                            ]}
                            isSearchable={false}
                            onChange={(country) => {
                              formik.setFieldValue(
                                "tax_percentage_surcharge",
                                country.value
                              );
                            }}
                            value={
                              values.tax_percentage_surcharge
                                ? {
                                    value: values.tax_percentage_surcharge,
                                    label:
                                      values.tax_percentage_surcharge + " %",
                                  }
                                : ""
                            }
                            isDisabled={
                              values.surcharge_end_date &&
                              new Date() >= values.surcharge_end_date
                            }
                            placeholder="0.00 %"
                          />
                        </Col>
                      )}
                    </Row>

                    {/* recurrencia */}
                    <Row className="mt-3">
                      {values.penalty && values.penalty != "" && (
                        <Col md={6} className="mb-2">
                          <FormSelect
                            label={t(
                              "products:modalSubconcept.lateFeeRecurrence"
                            )}
                            name="penalty_period"
                            options={surchargePeriods}
                            onChange={(event) => {
                              formik.setFieldValue(
                                "penalty_period",
                                event.value
                              );
                            }}
                            value={surchargePeriods.find(
                              (period) => period.value === values.penalty_period
                            )}
                            placeholder={t(
                              "products:modalSubconcept.lateFeeRecurrence"
                            )}
                          />
                        </Col>
                      )}
                      {/* RFC */}
                      <Col md={6} className="mb-2">
                        <Form.Label>
                          {t("products:modalSubconcept.RFCtoBill")}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <FormSelect
                          name="issuer_rfc_id"
                          options={rfcsIssuerList}
                          onChange={(event) => {
                            formik.setFieldValue("issuer_rfc_id", event.value);
                          }}
                          value={rfcsIssuerList.find(
                            (rfc) => rfc.value === values.issuer_rfc_id
                          )}
                          placeholder={t("products:modalSubconcept.none")}
                          showRequired={true}
                          required={true}
                        />
                      </Col>
                    </Row>

                    {/* notas */}
                    <Row>
                      <Col md={12} className="mb-2">
                        <FieldInput
                          label={t("products:modalSubconcept.notes")}
                          name="description"
                          type="text"
                          as="textarea"
                          placeholder={t(
                            "products:modalSubconcept.notesPlaceholder"
                          )}
                          style={{ resize: "none", height: "60px" }}
                          className="subconcept-modal"
                        />
                      </Col>
                    </Row>
                    {errors && (
                      <Row>
                        <Col md={12} className="mb-2">
                          <Alert variant="warning">
                            <span className="fw-bold">
                              {t("global:warning")}
                            </span>
                            {` ${errors.description}`}
                          </Alert>
                        </Col>
                      </Row>
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="outline-secondary"
                      disabled={loading}
                      onClick={props.onHide}
                    >
                      {t("global:buttons.cancel")}
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={
                        disableSaveButton(values) || !formik.isValid || loading
                      }
                    >
                      {loading ? (
                        <>
                          {t(
                            subconceptInfo.id
                              ? "global:buttons.saving"
                              : "global:buttons.textAdding"
                          )}
                          <Spinner
                            as="span"
                            animation="border"
                            role="status"
                            aria-hidden="true"
                            variant="white"
                            style={{
                              width: "0.81rem",
                              height: "0.81rem",
                              marginLeft: "3px",
                              borderWidth: "0.17em",
                            }}
                          />
                        </>
                      ) : (
                        t(
                          subconceptInfo.id
                            ? "global:buttons.save"
                            : "global:buttons.add"
                        )
                      )}
                    </Button>
                  </Modal.Footer>
                </FormOfFormik>
              )}
            </Formik>
          ) : (
            // lazy loading
            <div className="d-flex justify-content-center mt-5 mb-5">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </Modal>
      )}
      {/** Modal Success */}
      {showModalSuccess && (
        <ModalSuccess
          showModalSuccess={showModalSuccess}
          title={t(
            subconceptInfo.id
              ? "products:main.changesSaved"
              : "products:modalSubconcept.subconceptAdded"
          )}
          message={t(
            subconceptInfo.id
              ? "products:main.changesSavedSuccess"
              : "products:modalSubconcept.subconceptAddedSuccess"
          )}
          fnAcceptButton={props.onHide}
        />
      )}
    </>
  );
}

ModalSubconcept.propTypes = {
  productConceptId: PropTypes.number.isRequired,
  showSubconceptModal: PropTypes.bool,
  setShowSubconceptModal: PropTypes.func,
  cycleSelected: PropTypes.object,
  subconcept: PropTypes.object,
  onHide: PropTypes.func,
  setSubconceptSelected: PropTypes.func,
  subconcepts: PropTypes.array,
  conceptSelected: PropTypes.object,
};
