import React, { useContext, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { MainContext } from "../../../App";
import { Form as FormOfFormik, Formik } from "formik";
import * as Yup from "yup";

// Import API Services
import { SaveCategory } from "../../../api/Products";

// Import components
import ModalSuccess from "../../../components/global/modals/ModalSuccess";
import TextLoading from "../../../components/global/TextLoading";
import FieldInput from "../../../components/global/form/FieldInput";
import FormCheck from "../../../components/global/form/FormCheck";

// Set custom Styles
const customStyles = {
  iconsContainer: {
    height: "20vh",
    minHeight: "150px",
    overflowY: "scroll",
    overflowX: "hidden",
    padding: "20px 10px",
    border: "1px solid rgb(237, 237, 237)",
    borderRadius: "4px",
    scrollbarWidth: "thin",
    display: "inline-block",
  },
  icon: {
    display: "block",
    marginBottom: "5px",
  },
};

export default function ModalSaveCategory(props) {
  const prefix = process.env.REACT_APP_PREFIX;
  // Get data from local storage
  const organizationServices = JSON.parse(
    localStorage.getItem(`${prefix}Services`)
  );

  // Hooks
  const [t] = useTranslation(["products", "global"]);
  const { permissions } = useContext(MainContext);
  const [category] = useState(
    props.categoryToEdit || {
      name: "",
      bank_account_id: "",
      icon_id: props.icons[0].id,
    }
  );
  const [showModal, setShowModal] = useState(props.show);
  const [showModalSuccess, setShowSuccessModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState(false);
  const sapIsActive =
    organizationServices?.SAP?.active && permissions.sap_reports.edit;

  // Validate Formik form with Yup
  const validateSchema = Yup.object().shape({
    name: Yup.string().required(
      t("modalSaveCategory.nameCategory") + " " + t("global:isRequired")
    ),
    icon_id: Yup.string().required(
      t("modalSaveCategory.categoryIcon") + " " + t("global:isRequired")
    ),
    bank_account_id: Yup.string()
      .matches(
        /^[0-9a-zA-Z]+$/,
        t("modalSaveCategory.onlyAlphanumericCharacters")
      )
      .nullable(),
  });

  /**
   * Description: Method to save a category
   */
  const saveCategory = async (values) => {
    const payload = {
      name: values.name?.toUpperCase(),
      id: values.id,
      icon_id: values.icon_id,
      organization_id: localStorage.getItem(`cmOrganizationID${prefix}`),
      school_cycle_id: props.schoolCycle?.id,
    };
    if (sapIsActive) {
      payload.bank_account_id = values.bank_account_id;
    }
    setIsSaving(true);
    await SaveCategory(payload)
      .then((response) => {
        if (response.data) {
          setShowModal(false);
          setShowSuccessModal(true);
          // Update the category, if it exists else add it
          props.updateCategories((prev) => {
            let categories = [...prev];
            const category = response.data;
            if (payload.id) {
              const index = categories.findIndex((c) => c.id == category.id);
              category.icon_id = category.icon.id;
              categories[index] = category;
            } else {
              category.index = categories.length;
              category.icon_id = category.icon.id;
              categories.push(category);
            }
            props.updateCategorySelected(category, payload.id);
            return categories;
          });
        } else {
          setErrors(response);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <>
      {showModal && (
        <Modal show={showModal} onHide={props.onHide} backdrop={"static"}>
          <Modal.Header>
            <Modal.Title>
              {t(
                category.id
                  ? "modalSaveCategory.editCategory"
                  : "modalSaveCategory.addCategory"
              )}
            </Modal.Title>
          </Modal.Header>

          <Formik
            initialValues={category}
            validationSchema={validateSchema}
            onSubmit={(values) => {
              saveCategory(values);
            }}
          >
            {(formik) => (
              <FormOfFormik>
                <Modal.Body>
                  <Row>
                    <Col md={12}>
                      {/* Category Name */}
                      <Row>
                        <Col md={12}>
                          <FieldInput
                            name="name"
                            type="text"
                            style={{ textTransform: "uppercase" }}
                            label={t("modalSaveCategory.nameCategory")}
                            onInput={() => {
                              setErrors(false);
                            }}
                            placeholder={t(
                              "modalSaveCategory.nameCategoryPlaceholder"
                            )}
                            required={true}
                          />
                        </Col>
                      </Row>
                      {/* Category bank account id */}
                      {sapIsActive && (
                        <Row>
                          <Col md={12}>
                            <FieldInput
                              name="bank_account_id"
                              type="text"
                              label={t("modalSaveCategory.accountingAccountID")}
                              placeholder={t(
                                "modalSaveCategory.accountingAccountID"
                              )}
                              required={false}
                              onChange={(e) => {
                                setErrors(false);
                                const { value } = e.target;
                                const isValidInput = /^[0-9a-zA-Z]*$/.test(
                                  value
                                );
                                if (isValidInput) {
                                  formik.setFieldValue(
                                    "bank_account_id",
                                    value.replace(/\s+/g, "")
                                  );
                                }
                              }}
                              maxLength={100}
                            />
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col md={12}>
                          {/* Category icon */}
                          <Form.Label>
                            {t("modalSaveCategory.categoryIcon")}
                          </Form.Label>
                          <div style={customStyles.iconsContainer}>
                            <Row>
                              {props.icons.map((icon) => {
                                return (
                                  <Col
                                    key={icon.id}
                                    xs={1}
                                    className="text-center pb-4"
                                  >
                                    <i
                                      className={"fal " + icon.name + " fa-lg"}
                                      style={customStyles.icon}
                                      id={icon.id}
                                    />
                                    <FormCheck
                                      name="icon_id"
                                      type="radio"
                                      autoFocus={
                                        formik.values.icon_id == icon.id
                                      }
                                      checked={formik.values.icon_id == icon.id}
                                      onChange={() => {
                                        setErrors(false);
                                        formik.setFieldValue(
                                          "icon_id",
                                          icon.id
                                        );
                                      }}
                                      className="c-pointer"
                                    />
                                  </Col>
                                );
                              })}
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {/* Error message */}
                  {errors && (
                    <Row>
                      <Col md={12} className="mt-3">
                        <Alert variant="warning">{errors.description}</Alert>
                      </Col>
                    </Row>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <div>
                    <Button
                      variant="secondary"
                      onClick={props.onHide}
                      disabled={isSaving}
                    >
                      {t("global:buttons.cancel")}
                    </Button>

                    <Button variant="primary" type="submit" disabled={isSaving}>
                      {isSaving ? (
                        <TextLoading
                          text={t(
                            category.id
                              ? "global:buttons.saving"
                              : "global:buttons.textAdding"
                          )}
                          variant="light"
                        />
                      ) : (
                        t(
                          category.id
                            ? "global:buttons.save"
                            : "global:buttons.add"
                        )
                      )}
                    </Button>
                  </div>
                </Modal.Footer>
              </FormOfFormik>
            )}
          </Formik>
        </Modal>
      )}
      {/** Modal Success */}
      {showModalSuccess && (
        <ModalSuccess
          showModalSuccess={showModalSuccess}
          title={t(
            category.id
              ? "main.changesSaved"
              : "modalSaveCategory.categoryAdded"
          )}
          message={t(
            category.id
              ? "main.changesSavedSuccess"
              : "modalSaveCategory.categoryAddedSuccess"
          )}
          fnAcceptButton={props.onHide}
        />
      )}
    </>
  );
}

ModalSaveCategory.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  schoolCycle: PropTypes.object.isRequired,
  icons: PropTypes.array.isRequired,
  updateCategories: PropTypes.func.isRequired,
  categoryToEdit: PropTypes.object,
  updateCategorySelected: PropTypes.func.isRequired,
};
