import React, { useEffect, useState } from "react";
import { Col, Row, Dropdown, Popover, OverlayTrigger } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const customStyles = {
  reportCardBase: {
    backgroundColor: "#ffffff",
    padding: "40px",
    marginBottom: "20px",
  },
  columnClasses: {
    width: "25%",
  },
  columnPeriods: {
    width: "75%",
    overflowX: "auto",
    overflowY: "hidden",
  },
  columnScore: {
    // width: "25%",
    // minWidth: "80px",
    border: "solid 1px #e9ecef",
    borderRight: "none",
    borderLeft: "none",
  },
  columnScoreRed: {
    backgroundColor: "#FFF0F3",
    border: "solid 1px #e9ecef",
    borderRight: "none",
    borderLeft: "none",
  },
  verticalText: {
    writingMode: "vertical-rl",
    transform: "rotate(180deg)",
    textAlign: "center",
    // whiteSpace: "nowrap",
    borderRight: "solid 1px #e9ecef",
    borderTop: "solid 1px #e9ecef",
    borderBottom: "solid 1px #e9ecef",
    padding: "8px",
  },
  fillCaracter: {
    background:
      "linear-gradient(to right, black 50%, rgba(255, 255, 255, 0) 0%)",
    backgroundSize: "10px 1px" /* Ajusta el tamaño del carácter repetido */,
    backgroundRepeat: "repeat-x",
    color: "transparent" /* Hace que el texto sea invisible */,
    lineHeight: 3 /* Ajusta la altura de línea si es necesario */,
  },
  shadowRowHover: {
    boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.5)",
    "&:hover": {
      boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.5)",
    },
  },
  containerCriteria: {
    padding: 0,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    backgroundColor: "#D6DADB",
    border: "solid 1px #e9ecef",
    textAlign: "center",
    alignItems: "center",
    display: "grid",
  },

  containerScore: {
    padding: 0,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    border: "solid 1px #e9ecef",
    textAlign: "center",
    display: "grid",
  },
  containerAverage: {
    padding: 0,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    border: "solid 1px #e9ecef",
    textAlign: "center",
    display: "grid",
    backgroundColor: "#FAFAFA",
  },
};

const responseDummy = {
  organization_name: "Universidad de Chile",
  organization_logo_url:
    "https://i.pinimg.com/736x/1f/cf/cc/1fcfcc0e2c3a38e7d26f746d6f3973b6.jpg",
  student_name: "Juanito Perez",
  school_cycle_name: "2021-2022",
  school_level_name: "Primaria",
  grade_level: "1",
  grade_group: "A",
  elements: [
    {
      id: "1",
      type: "class",
      name: "Matematicas",
      periods: [
        {
          id: "1",
          score: "10",
          grading_criteria: [
            {
              id: "1",
              name: "Examen",
              percentage: "40",
              is_averageable: "true",
              score: "9",
              partials: [
                {
                  id: "1",
                  score: "8",
                },
                {
                  id: "2",
                  score: "10",
                },
              ],
            },
            {
              id: "2",
              name: "Tarea",
              percentage: "40",
              is_averageable: "true",
              score: "8",
              partials: [
                {
                  id: "1",
                  score: "8",
                },
                {
                  id: "2",
                  score: "10",
                },
              ],
            },
            {
              id: "3",
              name: "Participación",
              percentage: "20",
              is_averageable: "false",
              score: "7",
              partials: [
                {
                  id: "1",
                  score: "8",
                },
                {
                  id: "2",
                  score: "10",
                },
              ],
            },
          ],
          comments: "Comentario matematicas periodo 1 ",
          comment_id: "1",
        },
        {
          id: "2",
          score: "9",
          grading_criteria: [
            {
              id: "1",
              name: "Examen",
              percentage: "40",
              is_averageable: "true",
              score: "9",
              partials: [
                {
                  id: "1",
                  score: "8",
                },
                {
                  id: "2",
                  score: "10",
                },
              ],
            },
            {
              id: "2",
              name: "Tarea",
              percentage: "40",
              is_averageable: "true",
              score: "8",
              partials: [
                {
                  id: "1",
                  score: "8",
                },
                {
                  id: "2",
                  score: "10",
                },
              ],
            },
            {
              id: "3",
              name: "Participación",
              percentage: "20",
              is_averageable: "false",
              score: "7",
              partials: [
                {
                  id: "1",
                  score: "8",
                },
                {
                  id: "2",
                  score: "10",
                },
              ],
            },
          ],
          comments: "Comentario matematicas periodo 2 ",
          comment_id: "2",
        },
      ],
      average: "9.5",
    },
    {
      id: "20",
      type: "development_area",
      name: "Idiomas",
      classes: [
        {
          id: "21",
          type: "class",
          name: "Inglés",
          periods: [
            {
              id: "1",
              score: "10",
              grading_criteria: [
                {
                  id: "1",
                  name: "Examen",
                  percentage: "40",
                  is_averageable: "true",
                  score: "9",
                  partials: [
                    {
                      id: "1",
                      score: "8",
                    },
                    {
                      id: "2",
                      score: "10",
                    },
                  ],
                },
                {
                  id: "2",
                  name: "Tarea",
                  percentage: "40",
                  is_averageable: "true",
                  score: "8",
                  partials: [
                    {
                      id: "1",
                      score: "8",
                    },
                    {
                      id: "2",
                      score: "10",
                    },
                  ],
                },
                {
                  id: "3",
                  name: "Participación",
                  percentage: "20",
                  is_averageable: "false",
                  score: "7",
                  partials: [
                    {
                      id: "1",
                      score: "8",
                    },
                    {
                      id: "2",
                      score: "10",
                    },
                  ],
                },
              ],
              comments:
                "Estos comentarios son a nivel de periodo de evaluación",
              comment_id: "3",
            },
            {
              id: "2",
              score: "9",
              grading_criteria: [
                {
                  id: "1",
                  name: "Examen",
                  percentage: "40",
                  is_averageable: "true",
                  score: "9",
                  partials: [
                    {
                      id: "1",
                      score: "8",
                    },
                    {
                      id: "2",
                      score: "10",
                    },
                  ],
                },
                {
                  id: "2",
                  name: "Tarea",
                  percentage: "40",
                  is_averageable: "true",
                  score: "8",
                  partials: [
                    {
                      id: "1",
                      score: "8",
                    },
                    {
                      id: "2",
                      score: "10",
                    },
                  ],
                },
                {
                  id: "3",
                  name: "Participación",
                  percentage: "20",
                  is_averageable: "false",
                  score: "7",
                  partials: [
                    {
                      id: "1",
                      score: "8",
                    },
                    {
                      id: "2",
                      score: "10",
                    },
                  ],
                },
              ],
              comments:
                "Estos comentarios son a nivel de periodo de evaluación",
              comment_id: "4",
            },
          ],
        },
        {
          id: "22",
          type: "class",
          name: "Chino",
          periods: [
            {
              id: "1",
              score: "10",
              grading_criteria: [
                {
                  id: "1",
                  name: "Examen",
                  percentage: "40",
                  is_averageable: "true",
                  score: "9",
                  partials: [
                    {
                      id: "1",
                      score: "8",
                    },
                    {
                      id: "2",
                      score: "10",
                    },
                  ],
                },
                {
                  id: "2",
                  name: "Tarea",
                  percentage: "40",
                  is_averageable: "true",
                  score: "8",
                  partials: [
                    {
                      id: "1",
                      score: "8",
                    },
                    {
                      id: "2",
                      score: "10",
                    },
                  ],
                },
                {
                  id: "3",
                  name: "Participación",
                  percentage: "20",
                  is_averageable: "false",
                  score: "7",
                  partials: [
                    {
                      id: "1",
                      score: "8",
                    },
                    {
                      id: "2",
                      score: "10",
                    },
                  ],
                },
              ],
              comments:
                "Estos comentarios son a nivel de periodo de evaluación",
              comment_id: "5",
            },
            {
              id: "2",
              score: "9",
              grading_criteria: [
                {
                  id: "1",
                  name: "Examen",
                  percentage: "40",
                  is_averageable: "true",
                  score: "9",
                  partials: [
                    {
                      id: "1",
                      score: "8",
                    },
                    {
                      id: "2",
                      score: "10",
                    },
                  ],
                },
                {
                  id: "2",
                  name: "Tarea",
                  percentage: "40",
                  is_averageable: "true",
                  score: "8",
                  partials: [
                    {
                      id: "1",
                      score: "8",
                    },
                    {
                      id: "2",
                      score: "10",
                    },
                  ],
                },
                {
                  id: "3",
                  name: "Participación",
                  percentage: "20",
                  is_averageable: "false",
                  score: "7",
                  partials: [
                    {
                      id: "1",
                      score: "8",
                    },
                    {
                      id: "2",
                      score: "10",
                    },
                  ],
                },
              ],
              comments:
                "Estos comentarios son a nivel de periodo de evaluación",
              comment_id: "6",
            },
          ],
        },
      ],
      average: "9.5",
    },
    {
      id: "3",
      type: "class",
      name: "Historia",
      periods: [
        {
          id: "1",
          score: "8",
          grading_criteria: [
            {
              id: "1",
              name: "Examen",
              percentage: "40",
              is_averageable: "true",
              score: "9",
              partials: [
                {
                  id: "1",
                  score: "8",
                },
                {
                  id: "2",
                  score: "10",
                },
              ],
            },
            {
              id: "2",
              name: "Tarea",
              percentage: "40",
              is_averageable: "true",
              score: "8",
              partials: [
                {
                  id: "1",
                  score: "8",
                },
                {
                  id: "2",
                  score: "10",
                },
              ],
            },
            {
              id: "3",
              name: "Participación",
              percentage: "20",
              is_averageable: "false",
              score: "7",
              partials: [
                {
                  id: "1",
                  score: "8",
                },
                {
                  id: "2",
                  score: "10",
                },
              ],
            },
          ],
          comments: "Comentario Historia periodo 1",
          comment_id: "7",
        },
        {
          id: "2",
          score: "9",
          grading_criteria: [
            {
              id: "1",
              name: "Examen",
              percentage: "40",
              is_averageable: "true",
              score: "9",
              partials: [
                {
                  id: "1",
                  score: "8",
                },
                {
                  id: "2",
                  score: "10",
                },
              ],
            },
            {
              id: "2",
              name: "Tarea",
              percentage: "40",
              is_averageable: "true",
              score: "8",
              partials: [
                {
                  id: "1",
                  score: "8",
                },
                {
                  id: "2",
                  score: "10",
                },
              ],
            },
            {
              id: "3",
              name: "Participación",
              percentage: "20",
              is_averageable: "false",
              score: "7",
              partials: [
                {
                  id: "1",
                  score: "8",
                },
                {
                  id: "2",
                  score: "10",
                },
              ],
            },
          ],
          comments: "Comentario Historia periodo 2",
          comment_id: "8",
        },
      ],
      average: "8.5",
    },
    {
      id: "40",
      type: "development_area",
      name: "Saberes y pensamiento critico",
      classes: [
        {
          id: "23",
          type: "class",
          name: "Cívica",
          periods: [
            {
              id: "1",
              score: "7",
              grading_criteria: [
                {
                  id: "1",
                  name: "Examen",
                  percentage: "40",
                  is_averageable: "true",
                  score: "9",
                  partials: [
                    {
                      id: "1",
                      score: "8",
                    },
                    {
                      id: "2",
                      score: "10",
                    },
                  ],
                },
                {
                  id: "2",
                  name: "Tarea",
                  percentage: "40",
                  is_averageable: "true",
                  score: "8",
                  partials: [
                    {
                      id: "1",
                      score: "8",
                    },
                    {
                      id: "2",
                      score: "10",
                    },
                  ],
                },
                {
                  id: "3",
                  name: "Participación",
                  percentage: "20",
                  is_averageable: "false",
                  score: "7",
                  partials: [
                    {
                      id: "1",
                      score: "8",
                    },
                    {
                      id: "2",
                      score: "10",
                    },
                  ],
                },
              ],
              comments:
                "Estos comentarios son a nivel de periodo de evaluación",
              comment_id: "9",
            },
            {
              id: "2",
              score: "9",
              grading_criteria: [
                {
                  id: "1",
                  name: "Examen",
                  percentage: "40",
                  is_averageable: "true",
                  score: "9",
                  partials: [
                    {
                      id: "1",
                      score: "8",
                    },
                    {
                      id: "2",
                      score: "10",
                    },
                  ],
                },
                {
                  id: "2",
                  name: "Tarea",
                  percentage: "40",
                  is_averageable: "true",
                  score: "8",
                  partials: [
                    {
                      id: "1",
                      score: "8",
                    },
                    {
                      id: "2",
                      score: "10",
                    },
                  ],
                },
                {
                  id: "3",
                  name: "Participación",
                  percentage: "20",
                  is_averageable: "false",
                  score: "7",
                  partials: [
                    {
                      id: "1",
                      score: "8",
                    },
                    {
                      id: "2",
                      score: "10",
                    },
                  ],
                },
              ],
              comments:
                "Estos comentarios son a nivel de periodo de evaluación",
              comment_id: "10",
            },
          ],
        },
        {
          id: "24",
          type: "class",
          name: "Filosofía",
          periods: [
            {
              id: "1",
              score: "8",
              grading_criteria: [
                {
                  id: "1",
                  name: "Examen",
                  percentage: "40",
                  is_averageable: "true",
                  score: "9",
                  partials: [
                    {
                      id: "1",
                      score: "8",
                    },
                    {
                      id: "2",
                      score: "10",
                    },
                  ],
                },
                {
                  id: "2",
                  name: "Tarea",
                  percentage: "40",
                  is_averageable: "true",
                  score: "8",
                  partials: [
                    {
                      id: "1",
                      score: "8",
                    },
                    {
                      id: "2",
                      score: "10",
                    },
                  ],
                },
                {
                  id: "3",
                  name: "Participación",
                  percentage: "20",
                  is_averageable: "false",
                  score: "7",
                  partials: [
                    {
                      id: "1",
                      score: "8",
                    },
                    {
                      id: "2",
                      score: "10",
                    },
                  ],
                },
              ],
              comments:
                "Estos comentarios son a nivel de periodo de evaluación",
              comment_id: "11",
            },
            {
              id: "2",
              score: "9",
              grading_criteria: [
                {
                  id: "1",
                  name: "Examen",
                  percentage: "40",
                  is_averageable: "true",
                  score: "9",
                  partials: [
                    {
                      id: "1",
                      score: "8",
                    },
                    {
                      id: "2",
                      score: "10",
                    },
                  ],
                },
                {
                  id: "2",
                  name: "Tarea",
                  percentage: "40",
                  is_averageable: "true",
                  score: "8",
                  partials: [
                    {
                      id: "1",
                      score: "8",
                    },
                    {
                      id: "2",
                      score: "10",
                    },
                  ],
                },
                {
                  id: "3",
                  name: "Participación",
                  percentage: "20",
                  is_averageable: "false",
                  score: "7",
                  partials: [
                    {
                      id: "1",
                      score: "8",
                    },
                    {
                      id: "2",
                      score: "10",
                    },
                  ],
                },
              ],
              comments:
                "Estos comentarios son a nivel de periodo de evaluación",
              comment_id: "12",
            },
          ],
        },
      ],
      average: "8.5",
    },
    {
      id: "5",
      type: "class",
      name: "Educacion Fisica",
      periods: [
        {
          id: "1",
          score: "8",
          grading_criteria: [
            {
              id: "1",
              name: "Examen",
              percentage: "40",
              is_averageable: "true",
              score: "9",
              partials: [
                {
                  id: "1",
                  score: "8",
                },
                {
                  id: "2",
                  score: "10",
                },
              ],
            },
            {
              id: "2",
              name: "Tarea",
              percentage: "40",
              is_averageable: "true",
              score: "8",
              partials: [
                {
                  id: "1",
                  score: "8",
                },
                {
                  id: "2",
                  score: "10",
                },
              ],
            },
            {
              id: "3",
              name: "Participación",
              percentage: "20",
              is_averageable: "false",
              score: "7",
              partials: [
                {
                  id: "1",
                  score: "8",
                },
                {
                  id: "2",
                  score: "10",
                },
              ],
            },
          ],
          comments: "Estos comentarios son a nivel de periodo de evaluación",
          comment_id: "13",
        },
        {
          id: "2",
          score: "9",
          grading_criteria: [
            {
              id: "1",
              name: "Examen",
              percentage: "40",
              is_averageable: "true",
              score: "9",
              partials: [
                {
                  id: "1",
                  score: "8",
                },
                {
                  id: "2",
                  score: "10",
                },
              ],
            },
            {
              id: "2",
              name: "Tarea",
              percentage: "40",
              is_averageable: "true",
              score: "8",
              partials: [
                {
                  id: "1",
                  score: "8",
                },
                {
                  id: "2",
                  score: "10",
                },
              ],
            },
            {
              id: "3",
              name: "Participación",
              percentage: "20",
              is_averageable: "false",
              score: "7",
              partials: [
                {
                  id: "1",
                  score: "8",
                },
                {
                  id: "2",
                  score: "10",
                },
              ],
            },
          ],
          comments: "Estos comentarios son a nivel de periodo de evaluación",
          comment_id: "14",
        },
      ],
      average: "8.5",
    },
    {
      type: "average",
      periods: [
        {
          id: "1",
          average: "9",
          grading_criteria: [
            {
              id: "1",
              name: "Examen",
              percentage: "40",
              is_averageable: "true",
              score: "9",
              partials: [
                {
                  id: "1",
                  score: "8",
                },
                {
                  id: "2",
                  score: "10",
                },
              ],
            },
            {
              id: "2",
              name: "Tarea",
              percentage: "40",
              is_averageable: "true",
              score: "8",
              partials: [
                {
                  id: "1",
                  score: "8",
                },
                {
                  id: "2",
                  score: "10",
                },
              ],
            },
            {
              id: "3",
              name: "Participación",
              percentage: "20",
              is_averageable: "false",
              score: "7",
              partials: [
                {
                  id: "1",
                  score: "8",
                },
                {
                  id: "2",
                  score: "10",
                },
              ],
            },
          ],
          comments: "Estos comentarios son a nivel de periodo de evaluación",
          comment_id: "15",
        },
        {
          id: "2",
          average: "9",
          grading_criteria: [
            {
              id: "1",
              name: "Examen",
              percentage: "40",
              is_averageable: "true",
              score: "9",
              partials: [
                {
                  id: "1",
                  score: "8",
                },
                {
                  id: "2",
                  score: "10",
                },
              ],
            },
            {
              id: "2",
              name: "Tarea",
              percentage: "40",
              is_averageable: "true",
              score: "8",
              partials: [
                {
                  id: "1",
                  score: "8",
                },
                {
                  id: "2",
                  score: "10",
                },
              ],
            },
            {
              id: "3",
              name: "Participación",
              percentage: "20",
              is_averageable: "false",
              score: "7",
              partials: [
                {
                  id: "1",
                  score: "8",
                },
                {
                  id: "2",
                  score: "10",
                },
              ],
            },
          ],
          comments: "Estos comentarios son a nivel de periodo de evaluación",
          comment_id: "16",
        },
      ],
      total_average: "9",
    },
  ],
};

const height = window.innerHeight;

const ReportCardBreakdownComponent = () => {
  const { id } = useParams();
  const [t] = useTranslation(["global", "reportCards"]);

  const [reportCardInfo, setReportCardInfo] = useState(responseDummy);
  const [hasDevelopmentAreas, setHasDevelopmentAreas] = useState(false);
  const [periods, setPeriods] = useState([]);
  const [widthForPeriod, setWidthForPeriod] = useState(80); //TODO: pendiente de evaluar el minimo para etos periodos

  const [criterias, setCriterias] = useState([]);
  const [widthForCriteria, setWidthForCriteria] = useState(100); //

  const [heightForPeriod, setHeightForPeriod] = useState(69);

  const [availablePartials, setAvailablePartials] = useState(false);

  const [visiblePopover, setVisiblePopover] = useState({
    show: false,
    id: null,
  });
  const [dinamicHeightReport, setDinamicHeighReport] = useState({
    // maxHeight: height - 500 + "px",
    maxHeight: height - 400 + "px",
    overflowY: "auto",
    border: "solid 1px #e9ecef",
    marginLeft: 0,
    marginLight: 0,
  });
  useEffect(() => {
    setReportCardInfo(responseDummy);

    //buscar un elemento de tipo area de desarrollo
    const developmentAreaElement = responseDummy.elements.find(
      (element) => element.type === "development_area"
    );
    if (developmentAreaElement) setHasDevelopmentAreas(true);
    const classElement = responseDummy.elements.find(
      (element) => element.type === "class"
    );
    //obtener los peridos de la claseElement
    let periodsClass = [];
    if (classElement) {
      periodsClass = classElement.periods;
    } else {
      //obtener los peridos de la claseElement
      if (developmentAreaElement) {
        periodsClass = developmentAreaElement.classes[0].periods;
      }
    }
    setPeriods(periodsClass);
    // obtener los criterios
    const criteriaList = periodsClass[0].grading_criteria;
    setCriterias(criteriaList);
    // setWidthForCriteria(100 / periodsClass.length);

    // verificar si al menos un criterio tiene parciales
    const hasPartials = periodsClass.some((period) =>
      period.grading_criteria.some((criteria) => criteria.partials.length > 0)
    );
    setAvailablePartials(hasPartials);
  }, [id]);

  // monitorear el ancho de pantalla con useEffect
  useEffect(() => {
    const observerResize = () => {
      handleResize();
      let dinamicHeight = { ...dinamicHeightReport };
      dinamicHeight.maxHeight = window.innerHeight - 400 + "px";
      setDinamicHeighReport(dinamicHeight);
    };
    handleResize();
    window.addEventListener("resize", observerResize);
    return () => {
      window.removeEventListener("resize", observerResize);
    };
  }, [periods]);

  const handleResize = () => {
    const minWidth = 104;
    const widthScore = 100;
    const containerPeriods = document.getElementById("containerPeriods");
    const containerPeriodsWidth = containerPeriods.offsetWidth - widthScore;

    const widthPeriod = containerPeriodsWidth / periods.length;
    let newSizePeriod = widthPeriod > minWidth ? widthPeriod : minWidth;

    let minWidthCriteria = 60; //valor minimo soportado para cada criterio
    let widthCriteriaInitial = 60;

    if (periods.length > 0) {
      const criteriaList = periods[0].grading_criteria;
      const totalItelms = criteriaList.length + 2;
      widthCriteriaInitial = (newSizePeriod - 2) / totalItelms;
    }

    if (widthCriteriaInitial < minWidthCriteria) {
      const totalItelms = criterias.length + 2;
      newSizePeriod = minWidthCriteria * totalItelms;
      widthCriteriaInitial = minWidthCriteria - 1;
    }

    // algun periodo tiene parciales?
    const hasPartials = periods.some((period) =>
      period.grading_criteria.some((criteria) => criteria.partials.length > 0)
    );
    if (hasPartials) {
      // aumentar en 30 la altura de celda de periodo
      setHeightForPeriod(99);
    }

    setWidthForPeriod(newSizePeriod);
    setWidthForCriteria(widthCriteriaInitial);
    return;
  };

  const popoverComment = (comment) => {
    return (
      <Popover id="scoreComment">
        <Popover.Header>{reportCardInfo.student_name}</Popover.Header>
        <Popover.Body>{comment}</Popover.Body>
      </Popover>
    );
  };

  /**
   * Function to manage the click on the popover
   * @param {bool} show
   * @param {int} id
   * @returns
   */
  const handlePopoverClick = (show, id) => {
    if (!show) {
      if (visiblePopover.show && visiblePopover.id != id) {
        return;
      }
    }
    let popover = { ...visiblePopover };
    popover.show = show;
    popover.id = id;
    setVisiblePopover(popover);
  };

  return (
    <Row style={{ padding: "13px" }}>
      <Col xxl={12} xl={12} lg={12} xs={12} style={customStyles.reportCardBase}>
        {/* Head ReportCard */}
        <Row className="mb-4" style={{ marginLeft: 0, marginRight: 0 }}>
          <Col md={5} xs={12} className="ps-0">
            <Row>
              <div style={{ width: "max-content", display: "inline-block" }}>
                <img
                  src={reportCardInfo.organization_logo_url}
                  alt="logo"
                  style={{ maxWidth: "90px" }}
                />
              </div>

              <div style={{ width: "max-content", display: "inline-block" }}>
                <Row>
                  <Col md={12}>
                    <h6>
                      <b>{reportCardInfo.organization_name}</b>
                    </h6>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>{reportCardInfo.student_name}</Col>
                </Row>
              </div>
            </Row>
          </Col>
          <Col md={7} xs={12} className="pe-0">
            <Row>
              <Col md={4} xs={12}>
                <b>{t("reportCards:reportCardBasic.schoolCycle") + " :"} </b>
                {reportCardInfo.school_cycle_name}
              </Col>
              <Col md={4} xs={6}>
                <b>{t("reportCards:reportCardBasic.schooling") + " :"} </b>
                {reportCardInfo.school_level_name}
              </Col>
              <Col md={2} xs={3}>
                <b>{t("reportCards:reportCardBasic.grade") + " :"} </b>
                {reportCardInfo.grade_level}
              </Col>
              <Col md={2} xs={3} className="pe-0" style={{ textAlign: "end" }}>
                <b>{t("reportCards:reportCardBasic.group") + " :"} </b>
                {reportCardInfo.grade_group}
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Table info */}
        <Row style={dinamicHeightReport}>
          {/* Columna de lateral izquierda - items verticales (classes, develppment area, average) */}
          <div style={customStyles.columnClasses}>
            {hasDevelopmentAreas && (
              <Row>
                <div
                  className="align-middle"
                  style={{
                    border: "solid 1px #e9ecef",
                    height: availablePartials
                      ? heightForPeriod
                      : heightForPeriod - 30 + "px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <b>{t("reportCards:reportCardBreakdown.developmentAreas")}</b>
                </div>
              </Row>
            )}
            {reportCardInfo.elements.map((element, index) => {
              return (
                <Row key={index}>
                  {element.type === "class" && (
                    <>
                      <Col
                        md={3}
                        style={{
                          ...customStyles.verticalText,
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      ></Col>
                      <Col md={9}>
                        <Row>
                          <div
                            className="align-middle"
                            style={{
                              border: "solid 1px #e9ecef",
                              height: "40px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {element.name}
                          </div>
                        </Row>
                      </Col>
                    </>
                  )}
                  {element.type === "development_area" && (
                    <>
                      <Col
                        md={3}
                        style={{
                          ...customStyles.verticalText,
                          maxHeight: 40 * 2 + "px",
                          // whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        {element.name}
                      </Col>
                      <Col md={9}>
                        {element.classes.map((element, index) => {
                          return (
                            <Row key={index}>
                              <div
                                className="align-middle"
                                style={{
                                  border: "solid 1px #e9ecef",
                                  height: "40px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {element.name}
                              </div>
                            </Row>
                          );
                        })}
                      </Col>
                    </>
                  )}
                  {element.type === "average" && (
                    <div
                      style={{
                        border: "solid 1px #e9ecef",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <b>
                        {t("reportCards:reportCardBreakdown.generalAverage")}
                      </b>
                    </div>
                  )}
                </Row>
              );
            })}
          </div>

          {/* Periodos y calificaciones */}
          <div style={{ ...customStyles.columnPeriods }} id="containerPeriods">
            {/* periods, partials and criterias */}
            <Row style={{ width: "max-content" }}>
              <div
                className="px-0"
                style={{
                  display: "block ruby",
                  height: "40px",
                  width: widthForPeriod * periods.length + "px",
                }}
              >
                {periods.map((period, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        width: widthForPeriod + "px",
                        border: "solid 1px #e9ecef",
                        height: availablePartials
                          ? heightForPeriod
                          : heightForPeriod - 30 + "px",
                      }}
                    >
                      {/* Periodo header */}
                      <Row
                        style={{
                          height: "30px",
                          textAlign: "center",
                          alignItems: "center",
                        }}
                      >
                        <b>
                          {t("reportCards:reportCardBasic.period") +
                            " " +
                            period.id}
                        </b>
                      </Row>
                      {/* Selector de parciales */}
                      {availablePartials && (
                        <Row style={{ height: "30px", textAlign: "center" }}>
                          {/* add dropDown */}
                          <Dropdown className="noCaretDd">
                            <Dropdown.Toggle
                              id="dropdownReportCards"
                              variant="outline-secondary"
                              style={{
                                textAlign: "start",
                                width: "147px",
                              }}
                            >
                              {t("reportCards:reportCardBreakdown.allPeriods")}
                              <i
                                className="bi bi-chevron-down float-end"
                                style={{
                                  marginRight: 0,
                                  marginLeft: "5px",
                                  marginTop: "1px",
                                }}
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              style={{
                                textAlign: "start",
                                width: "147px",
                                minWidth: "147px",
                              }}
                            >
                              {/* mapear parciales */}
                              {period.grading_criteria[0].partials.map(
                                (partial, index) => {
                                  return (
                                    <Dropdown.Item
                                      key={index}
                                      style={{ width: "145px" }}
                                    >
                                      {t(
                                        "reportCards:reportCardBreakdown.partial"
                                      )}{" "}
                                      {partial.id}
                                    </Dropdown.Item>
                                  );
                                }
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Row>
                      )}
                      <Row
                        style={{
                          height: "38px",
                          paddingLeft: "11px",
                          paddingRight: "9px",
                        }}
                      >
                        {period.grading_criteria.map((criteria, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                ...customStyles.containerCriteria,
                                width: widthForCriteria + "px",
                              }}
                            >
                              <b
                                style={{
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                }}
                              >
                                {criteria.name}
                              </b>
                              <b>{criteria.percentage + "%"}</b>
                            </div>
                          );
                        })}
                        {/* promedio y comenarios header */}
                        <div
                          style={{
                            ...customStyles.containerCriteria,
                            width: widthForCriteria + "px",
                          }}
                        >
                          <b
                            style={{
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {t("reportCards:reportCardBasic.average")}
                          </b>
                        </div>
                        <div
                          style={{
                            ...customStyles.containerCriteria,
                            width: widthForCriteria + 4 + "px",

                            borderRight: "solid 1px #D6DADB",
                          }}
                        >
                          <b
                            style={{
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {t("reportCards:reportCardBreakdown.comments")}
                          </b>
                        </div>
                      </Row>
                    </div>
                  );
                })}
              </div>
              {/* header promedio campos forativos */}
              <div
                style={{
                  border: "solid 1px #e9ecef",
                  height: availablePartials
                    ? heightForPeriod
                    : heightForPeriod - 30 + "px",
                  width: "100px",
                }}
              >
                <Row style={{ height: availablePartials ? "60px" : "30px" }}>
                  <b> </b>
                </Row>
                <Row
                  style={{
                    height: "38px",
                    textAlign: "center",
                    alignItems: "center",
                    backgroundColor: "#D6DADB",
                    border: "solid 1px #e9ecef",
                  }}
                >
                  <b>
                    {t(
                      "reportCards:reportCardBreakdown.averageDevelopmentAreas"
                    )}
                  </b>
                </Row>
              </div>
            </Row>
            {/* Iterar elementos de boleta */}
            {reportCardInfo.elements.map((element, index) => {
              if (element.type === "class") {
                const numerOfperiods = element.periods.length;
                const space = widthForPeriod * numerOfperiods;
                return (
                  <Row key={index} style={{ width: "max-content" }}>
                    <div
                      className="px-0"
                      style={{
                        display: "block ruby",
                        height: "40px",
                        width: space + "px",
                      }}
                    >
                      {element.periods.map((period, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              border: "solid 1px #e9ecef",
                              height: "40px",
                              width: widthForPeriod + "px",
                              maxWidth: widthForPeriod + "px",
                            }}
                          >
                            <Row
                              style={{
                                height: "40px",
                                paddingLeft: "11px",
                                paddingRight: "10px",
                              }}
                            >
                              {period.grading_criteria.map(
                                (criteria, index) => {
                                  return (
                                    <div
                                      key={index}
                                      style={{
                                        ...customStyles.containerScore,
                                        width: widthForCriteria + "px",
                                        alignItems: "center",
                                      }}
                                    >
                                      {criteria.score}
                                    </div>
                                  );
                                }
                              )}

                              {/* Pomedio y comentarios valores */}
                              <div
                                style={{
                                  ...customStyles.containerAverage,
                                  width: widthForCriteria + "px",
                                  alignItems: "center",
                                }}
                              >
                                {period.score}
                              </div>
                              <div
                                style={{
                                  ...customStyles.containerScore,
                                  width: widthForCriteria + "px",
                                  alignItems: "center",
                                  borderRight: "none",
                                }}
                              >
                                <b
                                  style={{
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                >
                                  <OverlayTrigger
                                    trigger="click"
                                    placement="top"
                                    overlay={popoverComment(period.comments)}
                                    show={
                                      visiblePopover.show &&
                                      visiblePopover.id === period.comment_id
                                    }
                                    onToggle={(value) => {
                                      handlePopoverClick(
                                        value,
                                        period.comment_id
                                      );
                                    }}
                                    rootClose
                                  >
                                    <i
                                      className="bi bi-info-circle-fill"
                                      style={{
                                        color: "#ea2c54",
                                        fontSize: "18px",
                                      }}
                                    />
                                  </OverlayTrigger>
                                </b>
                              </div>
                            </Row>
                          </div>
                        );
                      })}
                    </div>
                    <div
                      style={{
                        width: "100px",
                        height: "40px",
                        alignItems: "center",
                        border: "solid 1px #e9ecef",
                        backgroundColor: "#FAFAFA",
                        textAlign: "center",
                        display: "grid",
                      }}
                    >
                      {element.average}
                    </div>
                  </Row>
                );
              }

              if (element.type === "development_area") {
                const numerOfperiods = element.classes[0].periods.length;

                const numberOfClasses = element.classes.length;
                const space = widthForPeriod * numerOfperiods;
                return (
                  <Row key={index} style={{ width: "max-content" }}>
                    <div
                      className="px-0"
                      style={{
                        height: 40 * numberOfClasses + "px",
                        width: space + "px",
                      }}
                    >
                      {element.classes.map((singleClass, c_index) => {
                        return (
                          <Row
                            className="px-0 mx-0"
                            key={c_index}
                            style={{
                              display: "block ruby",
                              height: "40px",
                            }}
                          >
                            {singleClass.periods.map((period, p_index) => {
                              return (
                                <div
                                  key={p_index}
                                  className="px-0"
                                  style={{
                                    maxWidth: widthForPeriod + "px",
                                    height: "40px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Row
                                    style={{
                                      height: "40px",
                                      paddingLeft: "12px",
                                      // paddingRight: "10px",
                                      // textAlign: "center",
                                      // display: "grid",
                                    }}
                                  >
                                    {period.grading_criteria.map(
                                      (criteria, index) => {
                                        return (
                                          <div
                                            key={index}
                                            style={{
                                              ...customStyles.containerScore,
                                              width: widthForCriteria + "px",
                                              alignItems: "center",
                                            }}
                                          >
                                            {criteria.score}
                                          </div>
                                        );
                                      }
                                    )}

                                    {/* Pomedio y comentarios valores */}
                                    <div
                                      style={{
                                        ...customStyles.containerAverage,
                                        width: widthForCriteria + "px",
                                        alignItems: "center",
                                      }}
                                    >
                                      {period.score}
                                    </div>
                                    <div
                                      style={{
                                        ...customStyles.containerScore,
                                        width: widthForCriteria + "px",
                                        alignItems: "center",
                                        borderRight: "none",
                                      }}
                                    >
                                      <b
                                        style={{
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                        }}
                                      >
                                        <OverlayTrigger
                                          trigger="click"
                                          placement="top"
                                          overlay={popoverComment(
                                            period.comments
                                          )}
                                          show={
                                            visiblePopover.show &&
                                            visiblePopover.id ===
                                              period.comment_id
                                          }
                                          onToggle={(value) => {
                                            handlePopoverClick(
                                              value,
                                              period.comment_id
                                            );
                                          }}
                                          rootClose
                                        >
                                          <i
                                            className="bi bi-info-circle-fill"
                                            style={{
                                              color: "#ea2c54",
                                              fontSize: "18px",
                                            }}
                                          />
                                        </OverlayTrigger>
                                      </b>
                                    </div>
                                  </Row>
                                </div>
                              );
                            })}
                          </Row>
                        );
                      })}
                    </div>
                    <div
                      style={{
                        width: "100px",
                        height: 40 * numberOfClasses + "px",
                        alignItems: "center",
                        border: "solid 1px #e9ecef",
                        backgroundColor: "#FAFAFA",
                        textAlign: "center",
                        display: "grid",
                      }}
                    >
                      {element.average}
                    </div>
                  </Row>
                );
              }

              if (element.type === "average") {
                return (
                  <Row key={index} style={{ width: "max-content" }}>
                    <div
                      className="ps-0"
                      style={{
                        display: "block ruby",
                        height: "40px",
                        width: widthForPeriod * element.periods.length + "px",
                      }}
                    >
                      {element.periods.map((period, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              border: "solid 1px #e9ecef",
                              height: "40px",
                              width: widthForPeriod + "px",
                              maxWidth: widthForPeriod + "px",
                            }}
                          >
                            <Row
                              style={{
                                height: "40px",
                                paddingLeft: "11px",
                                paddingRight: "10px",
                                // textAlign: "center",
                                // display: "grid",
                              }}
                            >
                              {period.grading_criteria.map(
                                (criteria, index) => {
                                  return (
                                    <div
                                      key={index}
                                      style={{
                                        ...customStyles.containerScore,
                                        width: widthForCriteria + "px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <b></b>
                                    </div>
                                  );
                                }
                              )}

                              {/* Pomedio y comentarios valores */}
                              <div
                                style={{
                                  ...customStyles.containerAverage,
                                  width: widthForCriteria + "px",
                                  alignItems: "center",
                                }}
                              >
                                <b
                                  style={{
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                >
                                  {element.total_average}
                                </b>
                              </div>
                              <div
                                style={{
                                  ...customStyles.containerScore,
                                  width: widthForCriteria + "px",
                                  alignItems: "center",
                                  borderRight: "none",
                                }}
                              >
                                <b
                                  style={{
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                ></b>
                              </div>
                            </Row>
                          </div>
                        );
                      })}
                    </div>
                    <div
                      style={{
                        width: "100px",
                        height: "40px",
                        display: "grid",
                        alignItems: "center",
                        border: "solid 1px #e9ecef",
                        backgroundColor: "#FAFAFA",
                        textAlign: "center",
                      }}
                    >
                      <b>0</b>
                    </div>
                  </Row>
                );
              }
            })}
          </div>
        </Row>
      </Col>
    </Row>
  );
};

export default ReportCardBreakdownComponent;
