import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Button, Row, Col, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";

import ModalSuccess from "../../global/modals/ModalSuccess";
import { DeleteSubconcept } from "../../../api/Products";

export default function EliminateSubconcept(props) {
  const [t] = useTranslation(["global", "products"]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(props.showEliminateSubconcept);
  const [showModalSuccess, setShowSuccessModal] = useState(false);

  const deleteSubconcept = () => {
    setLoading(true);
    const payload = {
      id: props.subconceptSelected.id,
    };

    DeleteSubconcept(payload).then(() => {
      // Find the position of the subitem to be deleted
      const index = props.subconcepts.findIndex(
        (subconcept) => subconcept.id === props.subconceptSelected.id
      );
      // Remove the subitem from the list
      props.subconcepts.splice(index, 1);
      // Update the list of subconcepts
      props.setSubconceptSelected(null);
      setLoading(false);
      setShowModal(false);
      setShowSuccessModal(true);
    });
  };

  return (
    <>
      {showModal && (
        <Modal
          show={showModal}
          aria-labelledby="contained-modal-title-lg"
          onHide={props.onHide}
          backdrop={"static"}
        >
          <Modal.Header>
            <Modal.Title className="modal-title">
              {t("products:modalRemoveSubconcept.title")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={12} className=" mb-2">
                {t("products:modalRemoveSubconcept.menssage", {
                  count: props.subconceptSelected?.students_counter || 0,
                })}
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="outline-secondary"
              disabled={loading}
              onClick={props.onHide}
            >
              {t("global:buttons.cancel")}
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={loading}
              onClick={deleteSubconcept}
            >
              {loading ? (
                <>
                  {t("global:buttons.deleting2")}
                  <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                    variant="white"
                    style={{
                      width: "0.81rem",
                      height: "0.81rem",
                      marginLeft: "3px",
                      borderWidth: "0.17em",
                    }}
                  />
                </>
              ) : (
                t("global:titlePermissions.delete")
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {/** Modal Success */}
      {showModalSuccess && (
        <ModalSuccess
          showModalSuccess={showModalSuccess}
          title={t("products:modalRemoveSubconcept.subconceptRemoved")}
          message={t("products:modalRemoveSubconcept.subconceptRemovedSuccess")}
          fnAcceptButton={props.onHide}
        />
      )}
    </>
  );
}

EliminateSubconcept.propTypes = {
  showEliminateSubconcept: PropTypes.bool,
  onHide: PropTypes.func,
  subconceptSelected: PropTypes.object,
  setSubconceptSelected: PropTypes.func,
  subconcepts: PropTypes.array,
};
