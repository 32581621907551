import axios from "axios";
import { validateErrorResponse } from "./AxiosExtension";

const axiosOptions = {
  url: process.env.REACT_APP_GRAPHQL_URL,
  method: "post",
};
const prefix = process.env.REACT_APP_PREFIX;

/**
 * Purpose: Gets the details of the organization I am logged in with
 * @returns Object
 */
export const organizationGet = async (CookieToken) => {
  let data = {
    token: CookieToken || localStorage.getItem(`cmToken${prefix}`),
    id: localStorage.getItem(`cmOrganizationID${prefix}`),
  };
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_REST_URL}/v1/organizations`,
      data
    );
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return error;
  }
};

/**
 * Purpose: Updates the organization when the organization change is made
 * @param {Number} organizationId
 * @returns Object
 */
export const setCurrentOrganization = async (organizationId) => {
  var data = {
    token: localStorage.getItem(`cmToken${prefix}`),
    id: organizationId,
  };
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_REST_URL}/v1/organizations`,
      data
    );
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * Purpose: set organization for the user
 * @param {Id} organizationId
 * @returns Object
 */
export const changeCurrentOrganization = async (organizationId) => {
  var data = {
    token: localStorage.getItem(`cmToken${prefix}`),
    organization_id: organizationId,
  };
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_REST_URL}/v1/users/set_current_organization`,
      data
    );
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * Purpose: Envia la notificación a la escuela
 * @param {Number} organizationServiceId
 * @returns
 */
export const serviceVote = async (organizationServiceId) => {
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/users/vote`;
  axiosOptions.data = {
    organization_service_id: organizationServiceId,
  };

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * Purpose: get time zone catalog
 *
 * @returns Object
 */
export const getTimesZone = async () => {
  var data = {
    token: localStorage.getItem(`cmToken${prefix}`),
  };
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_REST_URL}/v1/organizations/timezones`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Request to create an organism
 * @param {obj} payload
 * @returns JSON
 */
export const CreateOrganization = async (payload) => {
  let requestOptions = {};
  requestOptions.method = "post";
  requestOptions.data = payload;
  requestOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  requestOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/organizations/create`;
  try {
    const response = await axios(requestOptions);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

/**
 * Service to add a service to an organization
 * @param {obj} payload
 * @returns JSON
 */
export const AddOrganizationService = async (payload) => {
  let requestOptions = {};
  requestOptions.method = "post";
  requestOptions.data = payload;
  requestOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  requestOptions.url = `${process.env.REACT_APP_REST_URL}/v2/organization_subscriptions`;
  try {
    const response = await axios(requestOptions);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

/**
 *  Purpose: Get the school levels
 * @param {obj} data
 * @returns
 */
export const GetOrganization = async (data) => {
  let requestOptions = {};
  requestOptions.method = "get";
  requestOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  requestOptions.url = `${process.env.REACT_APP_REST_URL}/v2/organizations/list`;
  requestOptions.params = data;
  try {
    const response = await axios(requestOptions);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

/**
 * Deactivate organizations by id(s)
 * @param {obj} request
 * @returns
 */
export const DeleteOrganizations = async (request) => {
  let requestOptions = {};
  requestOptions.method = "delete";
  requestOptions.data = request;
  requestOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  requestOptions.url = `${process.env.REACT_APP_REST_URL}/v2/organizations`;
  try {
    const response = await axios(requestOptions);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

/**
 * Gets the services of the organization
 * @param {Object} request
 * @returns
 */
export const GetServices = async (organizationID) => {
  let axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/organization_services`;
  axiosOptions.params = {
    organization_id: organizationID,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return error.response.data;
  }
};

/**
 * Get the organization groups
 * @param {Object} request
 * @returns
 */
export const GetGroupOfOrganization = async (text) => {
  let axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.params = {
    search: text,
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/organizations_groups`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return error.response.data;
  }
};

/**
 * Description: Function to get the organizations of a group.
 * @param {object} [data={}]
 * @return {JSON} Response from API
 */
export const GetOrganizationGroupCycles = async (data = {}) => {
  let axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/organizations_groups/cycles`;
  axiosOptions.params = data;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};
