/**
 *       **** Readme ****
 *
 * Component: <FormInputLeftDropdown />
 * Purpose: Returns the Input element.
 *
 * Props:
 *   -required: {Boolean} Indicates if the field is required
 *   -showRequired: {Boolean} Indicates whether the "*" or the "optional" text is displayed
 *
 *
 * Creation date: 20/March/2023
 * Last update: 21/April/2023
 */

import React, { useEffect } from "react";
import { useField, useFormikContext } from "formik";
import PropTypes from "prop-types";
import { Form, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function FormInputLeftDropdown({ label, ...props }) {
  const [t] = useTranslation(["global"]);
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  /**
   * Initial loading
   */
  useEffect(() => {}, []);

  return (
    <Form.Group className={props.formGroupClassName || "mb-3"}>
      {label ? (
        <Form.Label
          data-text={t("optional")}
          htmlFor={props.id || props.name}
          className={
            props.required && props.showRequired
              ? "required"
              : !props.required && props.showRequired === false
              ? "optional"
              : ""
          }
        >
          {label}
          {props.complementaryLabel ? (
            <span className="fw-light fst-italic">
              {" (" + props.complementaryLabel + ")"}
            </span>
          ) : null}
        </Form.Label>
      ) : null}
      <div className="input-group">
        <span>
          <Dropdown>
            <Dropdown.Toggle
              id="dropdown-button-dark-example1"
              variant="outline-secondary"
              style={{ height: "39px" }}
              {...props}
            >
              {props.dropdownlabel}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {props.dropdownmenu.map((item, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    onClick={() => {
                      setFieldValue(props.dropdownfield, item.value);
                      if (props.dropdownOnClick) props.dropdownOnClick();
                    }}
                  >
                    {item.field}
                  </Dropdown.Item>
                );
              })}

              {/* <Dropdown.Item>%</Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </span>
        <Form.Control
          style={{ height: "39px" }}
          className={meta.touched && meta.error ? `errorValidation` : `well`}
          type="text"
          {...field}
          {...props}
        />
      </div>
      {meta.touched && meta.error ? (
        <Form.Text className="text-muted error">{meta.error}</Form.Text>
      ) : null}
    </Form.Group>
  );
}

FormInputLeftDropdown.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  showRequired: PropTypes.bool,
  dropdownlabel: PropTypes.string,
  dropdownfield: PropTypes.string,
  dropdownmenu: PropTypes.array,
  dropdownOnClick: PropTypes.func,
  complementaryLabel: PropTypes.string,
  formGroupClassName: PropTypes.string,
};
