import React, { useEffect, useState, useContext } from "react";
import {
  Container,
  Breadcrumb,
  Nav,
  Tab,
  Row,
  Button,
  Col,
} from "react-bootstrap";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";

/**Services */
import { GetReportCardList } from "../../api/ReportCards";
/*Elements*/
import ContentSolid from "../../components/global/ContentSolid";
import {
  goToHref,
  domainCookie,
  dateCookie,
} from "../../components/global/GlobalTools";
import SchoolCycle from "../../components/global/form/SchoolCycle";
import ContentTable from "../../components/table/ContentTable";
import { Table } from "../../components/table/Table";
import SelectFilter from "../../components/table/SelectFilter";
import InputFilter from "../../components/table/InputFilter";
import { LoadingTable } from "../../components/lazyLoading/LazyLoading";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { MainContext } from "../../App";

/**
 *
 * Save selected cycle
 */
const obtainFilterCycle = () => {
  let cycle = null;
  //Get the last cycle saved
  let cycleStringify = localStorage.getItem("filterCycleProspect");
  if (cycleStringify) {
    //parse last cycle
    let cycleProspect = cycleStringify && JSON.parse(cycleStringify);
    cycle = cycleProspect;
    //  Clean item in local storage
    localStorage.removeItem("filterCycleProspect");
  }
  return cycle;
};

const TableReportCardsViewList = () => {
  const [t] = useTranslation(["global", "reports", "table", "crm", "cycles"]);
  const [tabSelect] = useState("report_cards_list");
  const [widthSize, setWidthSize] = useState(window.innerWidth);
  const [mobileSize] = useState(810);
  const [btnMarginR, setBtnMarginR] = useState({
    marginRight: window.innerWidth <= mobileSize ? "0px" : "10px",
  });
  const [error, setError] = useState("");
  const [templatesCard, setTemplatesCard] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingView, setLoadingView] = useState(true);
  const [readyLanguages, setReadyLanguages] = useState(false);
  const [filters, setFilters] = useState([]);
  const [filterCycle, setFilterCycle] = useState("");
  const history = useHistory();
  const prefix = process.env.REACT_APP_PREFIX;
  const { permissions } = useContext(MainContext);
  const [cycleFilter, setCycleFilter] = useState(obtainFilterCycle);
  const [hasCycle, sethasCycle] = useState(false);

  /*Redirects to v1 and corresponding views */
  const goApp1 = (tabView) => {
    var now = new Date();
    now.setDate(now.getDate() + 5);
    goToHref(`/configuraciones/${tabView}`);
  };

  /**
   * Obtain complete information of the selected cycle
   * @param {int} cycleId
   */
  const getCycleSelectedInfo = (cycleId) => {
    const cycles = JSON.parse(localStorage.getItem(`cycles${prefix}`));
    const hasCycles = cycles && Array.isArray(cycles) && cycles.length;
    if (hasCycles) {
      const cycleSelected = cycles.find((cycle) => cycle.id == cycleId);
      setCycleFilter(cycleSelected);
    } else {
      setTimeout(() => {
        getCycleSelectedInfo();
      }, 1000);
    }
  };

  /* Gets the service from the API to be able to connect to it. */
  const getReportCardsList = (cycle) => {
    let request = {};
    let schoolCycleId = cycle && cycle.id ? cycle.id : cycle;
    request.school_cycle_id = schoolCycleId;
    setFilterCycle(cycle);
    setLoading(true);
    cycle && getCycleSelectedInfo(schoolCycleId);
    GetReportCardList(request)
      .then((result) => {
        if (result && result.description) {
          Sentry.captureException(Error(JSON.stringify(result)));
          setError(result.description);
        } else {
          let reports = result.data.data;
          reports = reports.map((item) => {
            return {
              id: item.id || "N/A",
              name: item.name || "N/A",
              creator_name: item.creator_name || "N/A",
              school_level_name: item.school_level_name || "N/A",
              grade_level_name: item.grade_level_name || "N/A",
              grade_group_name: item.grade_group_name || "N/A",
            };
          });
          setTemplatesCard(reports);
          setLoading(false);
          setLoadingView(false);
        }
      })
      .finally(() => {
        sethasCycle(true);
      });
  };

  /*It is used to detect the screen change and adjusts to the screens that the view is displayed. */
  window.onresize = () => {
    setWidthSize(window.innerWidth);
    setBtnMarginR(
      window.innerWidth <= mobileSize
        ? { marginRight: "0px" }
        : { marginRight: "10px" }
    );
  };

  /* Stores the table column data in memory, and is not updated unless useMemo identifies a change in the data. */
  const columns = React.useMemo(
    () => [
      {
        Header: t("crm:modalAcademicView.textAcademicLevel"),
        accessor: "school_level_name",
        Filter: (props) => <SelectFilter {...props} />,
        width: 110,
      },
      {
        Header: t("cycles:schoolCycleGroup.grade"),
        accessor: "grade_group_name",
        Filter: (props) => <SelectFilter {...props} />,
        width: 110,
      },
      {
        Header: t("cycles:schoolCycleGroup.group"),
        accessor: "grade_level_name",
        Filter: (props) => <SelectFilter {...props} />,
        width: 110,
      },
      {
        Header: t("crm:tableCrm.name"),
        accessor: "name",
        Filter: (props) => <InputFilter {...props} />,
        width: 110,
      },
      {
        Header: t("reports:headerTable.createBy"),
        accessor: "creator_name",
        Filter: (props) => <InputFilter {...props} />,
        width: 130,
      },
    ],
    [loading]
  );

  /* Initial loading */
  useEffect(() => {
    if (typeof t == "function" && !readyLanguages) {
      Sentry.setTag("section", "Report Cards");
      setReadyLanguages(true);
      //Retrieves the data of the filters
      const dataFilter = JSON.parse(localStorage.getItem("filtersReportCards"));
      //Get the last cycle saved
      const cycleReportCards = JSON.parse(
        localStorage.getItem("cycleReportCards")
      );
      if (dataFilter) {
        setFilters(dataFilter); //Update the filters with what's in the local storage
        localStorage.removeItem("filtersReportCards"); //Clean item in local storage
      }
      if (cycleReportCards) {
        setFilterCycle(cycleReportCards); //Update the cycle filter with what's in local storage
        localStorage.removeItem("cycleReportCards"); //Clean item in local storage
        getReportCardsList(cycleReportCards);
      }
    }
  }, [t]);

  //Saves the data for the table in memory, and is not updated unless useMemo identifies a change in the data
  const data = React.useMemo(() => templatesCard, [loading]);

  /**
   * Purpose: Action when you click on a table row
   * @param {Obj} lead // It is an object with the parameters of the prospectus
   * @param {Obj} state //Object that stores the status of filters and hidden columns.
   */
  const rowOnclick = (reports, state) => {
    const filters = state.filters; //Get the filters of the table
    localStorage.setItem("filtersReportCards", JSON.stringify(filters)); //Save the filters in the cookie
    localStorage.setItem("cycleReportCards", JSON.stringify(filterCycle)); //save the current cycle in the local storage
    history.push("/settings/report_cards/" + reports.id);
    Cookies.set(`sourceList${prefix}`, true, {
      expires: dateCookie(),
      domain: domainCookie(),
    });
  };

  /**
   * Description: Function to stop loading when the organization has no cycles
   */
  const stopLoading = () => {
    setLoading(false);
    setLoadingView(false);
    sethasCycle(false);
  };

  return (
    <Container fluid>
      <Breadcrumb>
        <Breadcrumb.Item href="#">
          <img
            className="icon-opacity"
            src={`${process.env.PUBLIC_URL}/img/dashboard-icons/default/configuration.svg`}
            style={{ height: "35px" }}
          />
          <i className="bi bi-chevron-right"></i>
        </Breadcrumb.Item>
      </Breadcrumb>

      <h2>{t("buttons.gradeReport")}</h2>

      <Tab.Container id="left-tabs-example">
        <Nav
          variant="pills"
          defaultActiveKey="report_cards_list"
          style={{ width: "fit-content", margin: "0 auto" }}
        >
          <Nav.Item>
            <Nav.Link eventKey="cycles" onClick={() => goApp1("ciclo")}>
              {t("buttons.cycle")}
            </Nav.Link>
          </Nav.Item>
          {permissions.config_organization.access && (
            <Nav.Item>
              <Nav.Link
                eventKey="organizations"
                onClick={() => goApp1("organizacion")}
              >
                {t("buttons.organization")}
              </Nav.Link>
            </Nav.Item>
          )}
          <Nav.Item>
            <Nav.Link
              eventKey="gradeReport"
              active={tabSelect == "report_cards_list"}
            >
              {t("buttons.gradeReport")}
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Tab.Container>
      <Row>
        <Col className="mt-3" md={12} xs={12} sm={12} lg={3}>
          {/** Filter Select School Cycle */}
          <SchoolCycle
            useIn="filters"
            handleOnchange={(e) => getReportCardsList(e?.id || null)}
            value={cycleFilter}
            selectedByDefault={true}
            getDefaultValues={filterCycle ? null : getReportCardsList}
            selectedCycleId={filterCycle}
            notCycles={stopLoading}
          />
        </Col>
        <Col md={12} xs={12} sm={12} lg={9}>
          <div
            className={
              widthSize <= mobileSize
                ? "d-grid gap-2"
                : "d-md-inline-block me-2 py-4 float-end"
            }
            style={
              widthSize <= mobileSize
                ? { marginRight: "0px", marginTop: "10px" }
                : { marginRight: "10px", display: "inline-block" }
            }
          >
            <Button
              variant="primary"
              style={btnMarginR}
              onClick={() => {
                history.push("/settings/report_cards/new");
                Cookies.set(`sourceList${prefix}`, true, {
                  expires: dateCookie(),
                  domain: domainCookie(),
                });
              }}
              disabled={!hasCycle}
            >
              {t("buttons.createBallot")}
            </Button>
          </div>
        </Col>
      </Row>
      <div className="mt-3">
        {loadingView ? (
          <ContentSolid style={{ marginTop: "20px" }}>
            <LoadingTable />
          </ContentSolid>
        ) : (
          <>
            {error}
            <ContentTable startColumFilter={0} lastColumFilter={8}>
              <Table
                columns={columns}
                data={data}
                loading={loading}
                rowOnclick={rowOnclick}
                filters={filters} //Contains the table filters
              />
            </ContentTable>
          </>
        )}
      </div>
    </Container>
  );
};

export default TableReportCardsViewList;

TableReportCardsViewList.propTypes = {
  data: PropTypes.array,
  rowOnclick: PropTypes.func,
  column: PropTypes.object,
  cell: PropTypes.func,
};
